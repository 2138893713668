import { useState, useEffect } from "react";
import { useFeaturesReady } from "../../../context/featuresReady/FeaturesReadyContext";
import { isValidTransactionAmount } from "./utils";
import { getFeature } from "../../../utils/features";

const usePaymentPromoConfig = (price) => {
  const { areFeaturesReady } = useFeaturesReady();
  const [featureConfig, setFeatureConfig] = useState(null);

  useEffect(() => {
    if (areFeaturesReady) {
      const paymentPromoFeature = getFeature("pdp-payment-promo-configs");

      const config =
        paymentPromoFeature?.isEnabled() &&
        paymentPromoFeature.getVariables().promoConfig;

      setFeatureConfig(config);
    }
  }, [areFeaturesReady]);

  return featureConfig?.providers?.length &&
    isValidTransactionAmount({
      paymentPromoProviders: featureConfig.providers,
      price,
    })
    ? featureConfig
    : null;
};

export default usePaymentPromoConfig;
