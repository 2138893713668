import PropTypes from "prop-types";
import styles from "./StandardSpinner.scss";
import classnamesBind from "classnames";
import {
  INLINE_SPINNER,
  SMALL_SPINNER,
  MEDIUM_SPINNER,
  LARGE_SPINNER,
} from "../../../constants";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";

const classnames = classnamesBind.bind(styles);

export default function StandardSpinner({
  additionalClasses,
  children,
  size,
  position = "relative",
}) {
  const { getTranslation } = useRuntimeConstants();
  return (
    <div
      className={classnames(styles.spinner, styles[size], styles[position], {
        [additionalClasses]: !!additionalClasses,
        [styles.spinnerDefaultBackground]: !additionalClasses,
      })}
      id="loadingSpinner"
      tabIndex={-1}
      aria-label={getTranslation("accessibility_label_loading")}
    >
      {children}
    </div>
  );
}

StandardSpinner.propTypes = {
  additionalClasses: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf([
    INLINE_SPINNER,
    SMALL_SPINNER,
    MEDIUM_SPINNER,
    LARGE_SPINNER,
  ]).isRequired,
  position: PropTypes.oneOf(["absolute", "relative"]),
};
