import fireSaveForLaterAnalytics from "../analytics/saveForLater";
import getSdks from "./sdks";
import { logError, ERROR_TYPE } from "./newRelicLogger";
import sendBeacon from "./sendBeacon";

const saveForLater = async ({ product, items, pageArea, looksProducts }) => {
  try {
    const savedSdk = await getSdks();
    const savedItemsSdk = await savedSdk.savedItems;
    await savedItemsSdk.addItems({ items });
    fireSaveForLaterAnalytics(product, pageArea, looksProducts);
    sendBeacon(product.id, "onWishlistBeacon");
  } catch (error) {
    logError(ERROR_TYPE.UserActionFailed, {
      action: "SaveForLater",
      message: error.message,
    });
  }
};

export default saveForLater;
