import {
  getModalTypes,
  openIFrameModal,
} from "../../../utils/siteChromeModals";
import getConstants from "../../../runtimeConstants";
import { SITE_CHROME_MODALS_URL } from "../../../constants";

const areWebContextParametersPresent = (webContext) =>
  webContext?.browseCountry && webContext?.browseLanguage;

const areAllQueryParametersPresent = ({ webContext, sellerId }) =>
  areWebContextParametersPresent(webContext) && sellerId;

export const openSellerInfoModal = ({
  focusOnCloseElement,
  iFrameTitle,
  sellerId,
  sourceId,
}) => {
  const { webContext } = getConstants();
  const modalType = getModalTypes()?.SIDE_RIGHT;

  if (areAllQueryParametersPresent({ webContext, sellerId })) {
    const { browseLanguage, browseCountry } = webContext;

    openIFrameModal({
      url: `${SITE_CHROME_MODALS_URL}?sellerId=${sellerId}&sourceId=${sourceId}&countryCode=${browseCountry}&lang=${browseLanguage}`,
      focusOnCloseElement,
      iFrameTitle,
      modalType,
      titleRequired: true,
    });
  }
};
