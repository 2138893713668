import PropTypes from "prop-types";

import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./PrimaryPrice.scss";
import { PRIMARY_PRICE, PRICE_TYPES } from "../priceTypes";
import CurrentPrice from "../currentPrice/CurrentPrice";
import PreviousPrice from "../previousPrice/PreviousPrice";
import PricingIcon from "../pricingIcon/PricingIcon";
import { getPrice, isDiscounted } from "../../../utils/discountPriceData";
import PercentageDiscount from "../percentageDiscount/PercentageDiscount";
import { isLowestPriceInLast30DaysEnabled } from "../../../utils/pricing";

export default function PrimaryPrice({
  priceData,
  showFromLabel,
  testId,
  priceType = PRIMARY_PRICE,
  firePricingModalOpen,
  setPricingIconLoad,
}) {
  const {
    getTranslation,
    webContext: { storeId, browseCountry },
  } = useRuntimeConstants();

  const isPriceDiscounted = isDiscounted(priceData);
  const isStoreEligibleForLowestPriceInLast30Days =
    isLowestPriceInLast30DaysEnabled(browseCountry);

  const {
    currentPriceText,
    previousPriceText,
    priceAriaLabel,
    percentageDiscountLabel,
    lowestPriceInLast30DaysText,
    lowestPriceInLast30DaysPercentageDiscountLabel,
  } = getPrice({
    translations: getTranslation,
    priceData,
    showFromLabel,
    showWasNowLabel: isPriceDiscounted,
    showLowestPriceInLast30Days: isStoreEligibleForLowestPriceInLast30Days,
  });

  return (
    <div data-testid={testId}>
      <span
        className={styles.screenReaderOnly}
        data-testid="price-screenreader-only-text"
      >
        {`${priceAriaLabel}${
          isPriceDiscounted ? ` (${percentageDiscountLabel})` : ""
        }`}
      </span>
      <CurrentPrice
        currentPriceText={currentPriceText}
        priceStyle={priceType}
        showVatMessage={storeId === "DE"}
        isSalePrice={isPriceDiscounted}
      />
      {isPriceDiscounted && (
        <>
          {renderLowestPriceInLast30Days({
            isStoreEligibleForLowestPriceInLast30Days,
            lowestPriceInLast30DaysText,
            lowestPriceInLast30DaysPercentageDiscountLabel,
            priceType,
          })}
          <div>
            <PreviousPrice
              previousPriceText={previousPriceText}
              priceStyle={priceType}
            />
            <PercentageDiscount
              percentageDiscountLabel={percentageDiscountLabel}
              style={priceType}
            />
            <PricingIcon
              priceData={priceData}
              firePricingModalOpen={firePricingModalOpen}
              setPricingIconLoad={setPricingIconLoad}
              isStoreEligibleForLowestPriceInLast30Days={
                isStoreEligibleForLowestPriceInLast30Days
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

function renderLowestPriceInLast30Days({
  isStoreEligibleForLowestPriceInLast30Days,
  lowestPriceInLast30DaysText,
  lowestPriceInLast30DaysPercentageDiscountLabel,
  priceType,
}) {
  return (
    !!(
      isStoreEligibleForLowestPriceInLast30Days &&
      lowestPriceInLast30DaysText &&
      lowestPriceInLast30DaysPercentageDiscountLabel
    ) && (
      <div data-testid="lowest-30-day-primary-price">
        <PreviousPrice
          previousPriceText={lowestPriceInLast30DaysText}
          priceStyle={priceType}
        />
        <PercentageDiscount
          percentageDiscountLabel={
            lowestPriceInLast30DaysPercentageDiscountLabel
          }
          highlightNegativeOnly={true}
          style={priceType}
        />
      </div>
    )
  );
}

PrimaryPrice.propTypes = {
  priceData: PropTypes.shape({
    current: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number,
    }).isRequired,
    previous: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number,
    }),
    rrp: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number,
    }),
    isMarkedDown: PropTypes.bool,
    isOutletPrice: PropTypes.bool,
    startDateTime: PropTypes.string,
  }).isRequired,
  showFromLabel: PropTypes.bool,
  testId: PropTypes.string.isRequired,
  priceType: PropTypes.oneOf(PRICE_TYPES),
  firePricingModalOpen: PropTypes.func,
  setPricingIconLoad: PropTypes.func,
};
