import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Button.scss";
import { BUTTON_TYPES } from "./ButtonTypes";
import { TEXT_SIZES } from "../../../constants";

const Button = ({
  id,
  children,
  handleClick,
  additionalClasses,
  type,
  size = TEXT_SIZES.london2,
  disabled,
  ariaLabel,
  buttonRef,
  dataTestId,
  isSelected,
  isTab,
}) => (
  <button
    className={classNames(styles.button, styles[type], styles[size], {
      [additionalClasses]: !!additionalClasses,
    })}
    id={id}
    onClick={handleClick}
    disabled={disabled}
    aria-label={ariaLabel}
    ref={buttonRef}
    data-testid={dataTestId}
    role={isTab ? "tab" : "button"}
    aria-selected={isSelected}
  >
    {children}
  </button>
);

const Element = () =>
  typeof Element === "undefined" ? function () {} : Element;

Button.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  handleClick: PropTypes.func.isRequired,
  additionalClasses: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  size: PropTypes.oneOf(Object.values(TEXT_SIZES)),
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  buttonRef: PropTypes.shape({ current: PropTypes.instanceOf(Element()) }),
  dataTestId: PropTypes.string,
  isTab: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default Button;
