import { getFeature } from "../../utils/features";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";

const isRatingDataValid = (ratings, reviewNumberThreshold) =>
  ratings?.averageOverallStarRating &&
  parseFloat(ratings?.totalReviewCount) >= reviewNumberThreshold;

const useRatingsFeatureRequirements = (ratings, isInStock) => {
  const { reviewNumberThreshold, features: serverFeatures } =
    useRuntimeConstants();
  const { areFeaturesReady } = useFeaturesReady();

  return (
    serverFeatures.ratings &&
    isRatingDataValid(ratings, reviewNumberThreshold) &&
    isInStock === true &&
    areFeaturesReady &&
    getFeature("pdp-ratings-and-reviews").isEnabled()
  );
};

export default useRatingsFeatureRequirements;
