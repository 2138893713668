import getWindow from "../utils/window";
import {
  getFitAssistantSize,
  getFitAssistantStatus,
} from "./fitAssistantCommon";
import { waitForPageLoadAnalytics } from "./pageLoad";
import { getCommons, getGlobalEvars } from "./common";
import { getFitAssistantEventCodeString } from "./fitAssistantEventCode";
import { setEvents, setLinkTrackEvents } from "./events";

async function setCommonFitAssistantTrackingValues({
  recommendedSize,
  outOfScale,
  isPartialRecommendation,
  usedPastPurchases,
  category,
  customerPreferences,
  outOfStock,
  metrics,
  storeCode,
  product,
}) {
  const s = getWindow().s;

  s.products = `;${product.id}`;

  s.prop36 = await getFitAssistantStatus({
    recommendedSize,
    outOfScale,
    isPartialRecommendation,
    usedPastPurchases,
    category,
    customerPreferences,
    outOfStock,
  });
  if (metrics?.length) {
    s.prop70 = getFitAssistantEventCodeString({ metrics });
  }

  s.eVar50 = getFitAssistantSize({ storeCode, recommendedSize });
}

export async function fireOutOfScaleFitAssistantAnalytics({
  recommendedSize,
  outOfScale,
  isPartialRecommendation,
  usedPastPurchases,
  category,
  customerPreferences,
  outOfStock,
  storeCode,
  metrics,
  product,
}) {
  await waitForPageLoadAnalytics;

  const commons = getCommons();
  const s = getWindow().s;

  await setCommonFitAssistantTrackingValues({
    recommendedSize,
    outOfScale,
    isPartialRecommendation,
    usedPastPurchases,
    category,
    customerPreferences,
    outOfStock,
    storeCode,
    metrics,
    product,
  });

  s.tl(true, "o", "out of scale", {
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "prop36",
      "prop70",
      "eVar50",
    ]),
  });
}

export async function fireOpenFitAssistantAnalytics({
  recommendedSize,
  outOfScale,
  isPartialRecommendation,
  usedPastPurchases,
  category,
  customerPreferences,
  outOfStock,
  storeCode,
  metrics,
  product,
}) {
  await waitForPageLoadAnalytics;

  const commons = getCommons();
  const s = getWindow().s;

  await setCommonFitAssistantTrackingValues({
    recommendedSize,
    outOfScale,
    isPartialRecommendation,
    usedPastPurchases,
    category,
    customerPreferences,
    outOfStock,
    storeCode,
    metrics,
    product,
  });

  setEvents({
    events: ["event51", "event124"],
    trackingName: "fitAssistantOpen",
  });

  s.tl(true, "o", "launch widget", {
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "prop36",
      "prop70",
      "eVar50",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: "fitAssistantOpen" }),
  });
}

export async function fireCloseFitAssistantAnalytics({
  recommendedSize,
  outOfScale,
  isPartialRecommendation,
  usedPastPurchases,
  category,
  customerPreferences,
  outOfStock,
  storeCode,
  metrics,
  product,
}) {
  await waitForPageLoadAnalytics;

  const commons = getCommons();
  const s = getWindow().s;

  await setCommonFitAssistantTrackingValues({
    recommendedSize,
    outOfScale,
    isPartialRecommendation,
    usedPastPurchases,
    category,
    customerPreferences,
    outOfStock,
    storeCode,
    metrics,
    product,
  });

  setEvents({
    events: ["event124"],
    trackingName: "fitAssistantClose",
  });

  s.tl(true, "o", "close widget", {
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "prop36",
      "prop70",
      "eVar50",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: "fitAssistantClose" }),
  });
}
