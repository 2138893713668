import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./Error.scss";

const classnames = classnamesBind.bind(styles);

export default function Error({ text }) {
  return (
    <div className={styles.container}>
      <span className={classnames(styles.infoIcon, "product-information")} />
      {text}
    </div>
  );
}

Error.propTypes = {
  text: PropTypes.string.isRequired,
};
