import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ModalOverlay.scss";
import getWindow from "../../utils/window";
import classnamesBind from "classnames/bind";
import { MODAL_OVERLAY_TYPES } from "./modalOverlayTypes";

const classnames = classnamesBind.bind(styles);

export default function ModalOverlay({
  children,
  type = MODAL_OVERLAY_TYPES.light,
  modalId,
}) {
  const [parentModalScrollTop, setParentModalScrollTop] = useState(0);

  useEffect(() => {
    const parentModal = getWindow().document.getElementById(modalId);

    if (!parentModal) return;

    setParentModalScrollTop(parentModal.scrollTop);
    const currentOverflowY = parentModal.style.overflowY;
    const currentScrollPaddingTop = parentModal.style.scrollPaddingTop;
    parentModal.style.overflowY = "hidden";
    parentModal.style.scrollPaddingTop = 0;

    return () => {
      parentModal.style.overflowY = currentOverflowY;
      parentModal.style.scrollPaddingTop = currentScrollPaddingTop;
    };
  }, [modalId]);

  return (
    <div
      className={classnames(styles.container, MODAL_OVERLAY_TYPES[type])}
      style={{ top: `${parentModalScrollTop}px` }}
    >
      {children}
    </div>
  );
}
ModalOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.keys(MODAL_OVERLAY_TYPES)),
  modalId: PropTypes.string.isRequired,
};
