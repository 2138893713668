import PropTypes from "prop-types";
import styles from "./PreviousPrice.scss";
import classnamesBind from "classnames/bind";
import {
  GRID_PRICE,
  PRICE_TYPES,
  PRIMARY_PRICE,
  SECONDARY_PRICE,
  COMPLEMENTARY_PRIMARY_PRICE,
} from "../priceTypes";

const classnames = classnamesBind.bind(styles);

export default function PreviousPrice({
  previousPriceText,
  priceStyle = PRIMARY_PRICE,
}) {
  return (
    <span
      aria-hidden={true}
      className={classnames({
        primaryPrice: priceStyle === PRIMARY_PRICE,
        secondaryPrice: priceStyle === SECONDARY_PRICE,
        gridPrice: priceStyle === GRID_PRICE,
        complementaryPrimaryPrice: priceStyle === COMPLEMENTARY_PRIMARY_PRICE,
      })}
      data-testid="previous-price"
    >
      {previousPriceText}
    </span>
  );
}

PreviousPrice.propTypes = {
  previousPriceText: PropTypes.string.isRequired,
  priceStyle: PropTypes.oneOf(PRICE_TYPES),
};
