import { useState } from "react";
import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./SaveForLaterButton.scss";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { useSaveForLater } from "../../../context/saveForLater/SaveForLaterContext";
import { useLiveRegion } from "../../../context/liveRegion/LiveRegionContext";

const classnames = classnamesBind.bind(styles);

const SaveForLaterButton = ({
  handleClick,
  productTitle,
  saveForLaterClass,
  onBlur,
  variantIdentifier,
}) => {
  const { updateLiveRegion } = useLiveRegion();
  const { getTranslation } = useRuntimeConstants();
  const [isHoveringOverElement, setIsHoveringOverElement] = useState(false);
  const [flutterStyleProps, setFlutterStyleProps] = useState([{}, {}, {}]);
  const { isSavedForLater, saveForLater } = useSaveForLater();

  const handleSaveClick = (event) => {
    handleClick(event);

    updateLiveRegion(
      `${productTitle} - ${getTranslation("pdp_cta_save_for_later_active")}`
    );

    setFlutterStyleProps([
      genereateStyles(),
      genereateStyles(),
      genereateStyles(),
    ]);

    saveForLater(variantIdentifier);

    setIsHoveringOverElement(false);
  };

  const mouseEventHandler = ({ type }) => {
    if (isSavedForLater(variantIdentifier)) {
      return;
    }

    if (type === "mouseenter") {
      setIsHoveringOverElement(true);
      return;
    }

    if (type === "mouseleave") {
      setIsHoveringOverElement(false);
    }
  };

  const ariaLabel = isSavedForLater(variantIdentifier)
    ? getTranslation("pdp_cta_save_for_later_active")
    : getTranslation("pdp_cta_save_for_later");

  return (
    <button
      className={classnames(styles.saveForLater, saveForLaterClass)}
      onClick={handleSaveClick}
      aria-label={ariaLabel}
      disabled={isSavedForLater(variantIdentifier)}
      onMouseEnter={mouseEventHandler}
      onMouseLeave={mouseEventHandler}
      onBlur={onBlur}
      data-testid="saveForLater"
    >
      <span
        className={classnames(styles.icon, {
          "product-heartfull":
            isSavedForLater(variantIdentifier) || isHoveringOverElement,
          "product-heartempty": !(
            isSavedForLater(variantIdentifier) || isHoveringOverElement
          ),
          animation: isSavedForLater(variantIdentifier),
        })}
      />
      <span className={styles.flutters}>
        {flutterStyleProps.map((flutterStyle, index) => (
          <span
            key={index}
            style={flutterStyle}
            className={classnames(
              styles.icon,
              styles.flutterHeart,
              "product-heartfull",
              {
                flutterAnimation: isSavedForLater(variantIdentifier),
              }
            )}
          />
        ))}
      </span>
    </button>
  );
};

function genereateStyles() {
  const plusOrMinusTop = Math.random() > 0.5 ? "+" : "-";
  const plusOrMinusLeft = Math.random() > 0.5 ? "+" : "-";

  const firstPointLeft = randomFlutterDistance(2, 5);
  const secondPointLeft = firstPointLeft + randomFlutterDistance(2, 7);
  const thirdPointLeft = secondPointLeft + randomFlutterDistance(2, 15);

  const firstPointTop = randomFlutterDistance(2, 5);
  const secondPointTop = firstPointTop + randomFlutterDistance(7, 12);
  const thirdPointTop = secondPointTop + randomFlutterDistance(2, 15);

  return {
    "--translateX": `${plusOrMinusLeft}1`,
    "--translateY": `${plusOrMinusTop}1`,
    "--distanceFirstTop": `${firstPointTop}px`,
    "--distanceSecondTop": `${secondPointTop}px`,
    "--distanceThirdTop": `${thirdPointTop}px`,
    "--distanceFirstLeft": `${firstPointLeft}px`,
    "--distanceSecondLeft": `${secondPointLeft}px`,
    "--distanceThirdLeft": `${thirdPointLeft}px`,
  };
}

function randomFlutterDistance(min, max) {
  return Math.random() * (max - min) + min;
}

SaveForLaterButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  productTitle: PropTypes.string.isRequired,
  saveForLaterClass: PropTypes.string,
  onBlur: PropTypes.func,
  variantIdentifier: PropTypes.string.isRequired,
};

export default SaveForLaterButton;
