import PropTypes from "prop-types";
import { getProductSourceSellerInfo } from "../../../../utils/sourceSellerHelper";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import classnamesBind from "classnames/bind";
import SellerInfo from "../sellerInfo/SellerInfo";
import styles from "./SoldShippedHeader.scss";
import { TEXT_SIZES } from "../../../constants";
import { useProduct } from "../../../context/product/ProductContext";
import { useVariant } from "../../../context/variant/VariantContext";
import { getInStockVariants } from "../../../../utils/variantUtils";

const classnames = classnamesBind.bind(styles);

const getVariants = ({ mixAndMatchVariants, product, selectedVariant }) => {
  if (mixAndMatchVariants) {
    return mixAndMatchVariants;
  }
  return selectedVariant
    ? [selectedVariant]
    : getInStockVariants(product?.variants);
};

const SoldShippedHeader = ({
  mixAndMatchVariants,
  fireSellerInfoAnalytics,
  size = TEXT_SIZES.leavesden4,
}) => {
  const productContext = useProduct();
  const variantContext = useVariant();
  const { getTranslation } = useRuntimeConstants();

  let copy;
  let ariaLabel;
  const variants = getVariants({
    mixAndMatchVariants,
    product: productContext?.product,
    selectedVariant: variantContext?.selectedVariant,
  });
  const sourceSellerInfo = getProductSourceSellerInfo({
    variants,
  });

  if (sourceSellerInfo.isPartnerFulfils) {
    copy = getTranslation("dtc_seller_label", {
      sellerName: `<b>${sourceSellerInfo.sellerLabel}</b>`,
    });
    ariaLabel = getTranslation("dtc_seller_label", {
      sellerName: sourceSellerInfo.sellerLabel,
    });
  } else if (sourceSellerInfo.isAsosFulfils) {
    copy = getTranslation("afs_seller_label", {
      sellerName: `<b>${sourceSellerInfo.sellerLabel}</b>`,
    });
    ariaLabel = getTranslation("afs_seller_label", {
      sellerName: `${sourceSellerInfo.sellerLabel}`,
    });
  } else if (sourceSellerInfo.isSecondary) {
    copy = getTranslation("source_label", {
      sourceLocation: `<b>${sourceSellerInfo.sourceLabel}</b>`,
    });
    ariaLabel = getTranslation("source_label", {
      sourceLocation: `${sourceSellerInfo.sourceLabel}`,
    });
  }

  if (copy) {
    return (
      <div className={classnames(styles.shippingLabel, styles[size])}>
        <div
          data-testid="shippedAndSold__seller"
          role="heading"
          aria-live="polite"
          aria-label={ariaLabel}
          aria-level={2}
        >
          <span
            aria-hidden={true}
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
          />
          <SellerInfo
            data-testid="shippedAndSold__sellerButton"
            sellerId={sourceSellerInfo.sellerId}
            sourceId={sourceSellerInfo.sourceId}
            variants={variants}
            fireSellerInfoAnalytics={fireSellerInfoAnalytics}
          />
        </div>
      </div>
    );
  }

  return null;
};

SoldShippedHeader.propTypes = {
  mixAndMatchVariants: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.oneOf(Object.keys(TEXT_SIZES)),
  fireSellerInfoAnalytics: PropTypes.func,
};

export default SoldShippedHeader;
