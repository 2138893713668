import { IMAGE_SUFFIXES } from "../../client/constants";

export default function getImageSource(imageUrl, imageType, imageSize) {
  return `${imageUrl}${IMAGE_SUFFIXES[imageType][imageSize]}`;
}

export function getImageSourceSet(imageUrl, imageType) {
  return Object.entries(IMAGE_SUFFIXES[imageType]).map(
    ([screenWidth, imagePreset]) => {
      return `${imageUrl}${imagePreset} ${screenWidth}${"w"}`;
    }
  );
}

export function getImageAttributes(images, imageType, imageSize) {
  return images.map((image) => ({
    src: getImageSource(image.url, imageType, imageSize),
    srcset: getImageSourceSet(image.url, imageType).join(","),
    alternateText: image.alternateText,
  }));
}
