import PropTypes from "prop-types";
import { useVariant } from "../../context/variant/VariantContext";

export default function ShowOnVariantStockStatus({
  children,
  variantStockStatus,
}) {
  const { selectedVariant } = useVariant();

  return variantStockStatus === selectedVariant?.isInStock ? (
    <>{children}</>
  ) : null;
}

ShowOnVariantStockStatus.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variantStockStatus: PropTypes.bool.isRequired,
};
