import { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./Modal.scss";
import getWindow from "../../utils/window";
import { MODAL_TYPE } from "../../constants";
import BrandedSpinner from "../spinner/branded/BrandedSpinner";
import useEventListener from "../../hooks/useEventListener";
import useTrapFocus from "../../hooks/useTrapFocus";
import ModalOverlay from "../modalOverlay/ModalOverlay";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const { SIDE, CENTER, CENTER_LARGE } = MODAL_TYPE;

const classnames = classnamesBind.bind(styles);
const MODAL_ANIMATION_TIME = 500;

export default function Modal({
  title,
  subtitle,
  open,
  contentLoaded,
  focusOnClose,
  closeModal,
  children,
  modalId,
  handleOverlayClick,
  overlayOpen,
  modalType,
  withScrollBar = true,
  hideCloseButton,
  forceDOMRewrite,
  useFocusTrapCatchAll,
  withDarkThemeSupport = false,
}) {
  const [shouldRender, setShouldRender] = useState(open);
  const [shouldRenderOverlay, setShouldRenderOverlay] = useState(open);
  const [resetTrap, setResetTrap] = useState(false);
  const derenderTimeout = useRef(null);
  const firstRender = useRef(true);
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);
  const { getTranslation } = useRuntimeConstants();
  const closeButtonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.key === "escape") {
      if (!overlayOpen) {
        closeModal();
      }
    }
  };

  const clickOverlay = () => {
    closeModal();
    handleOverlayClick && handleOverlayClick();
  };

  useEventListener("keydown", handleKeyDown, []);

  useEffect(() => {
    const derenderCallback = () => {
      const derender = () => {
        setShouldRender(!forceDOMRewrite);
        setShouldRenderOverlay(false);
        getWindow().document.body.style.overflow = "";
        focusOnClose?.focus();
      };
      if (modalType === SIDE) {
        clearTimeout(derenderTimeout.current);
        derenderTimeout.current = setTimeout(() => {
          derender();
        }, MODAL_ANIMATION_TIME);
        return;
      }
      derender();
    };

    if (!open && !firstRender.current) {
      derenderCallback();
    } else if (!firstRender.current) {
      clearTimeout(derenderTimeout.current);
      getWindow().document.body.style.overflow = "hidden";
      setShouldRenderOverlay(true);
      setShouldRender(true);
    }
  }, [open, modalType, forceDOMRewrite, focusOnClose]);

  const closeButton = useCallback(
    (node) => {
      closeButtonRef.current = node;
      let timeout;
      if (open && node) {
        timeout = setTimeout(() => {
          node.focus();
        }, MODAL_ANIMATION_TIME);
      }

      if (!node) {
        clearTimeout(timeout);
      }
    },
    [open]
  );

  useEffect(() => {
    if (!contentLoaded && open && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [open, contentLoaded]);

  useEffect(() => {
    setResetTrap(shouldRender && contentLoaded);
  }, [shouldRender, contentLoaded]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  const setModalRef = useTrapFocus({
    resetTrap,
    useCatchAll: useFocusTrapCatchAll,
  });

  const getModalTypeStyle = () => ({
    center: modalType === CENTER || modalType === CENTER_LARGE,
    large: modalType === CENTER_LARGE,
    side: modalType === SIDE,
  });

  const renderCloseButton = () => (
    <button
      className={styles.close}
      aria-label={getTranslation("pdp_misc_close")}
      onClick={closeModal}
      data-testid="closeModal"
      ref={closeButton}
    >
      <div className={styles.icon} />
    </button>
  );

  const renderOverlay = () =>
    shouldRenderOverlay && (
      <div
        className={classnames(styles.overlay, {
          stopScroll: overlayOpen,
          open: open,
          ...getModalTypeStyle(),
        })}
        aria-hidden={true}
        onClick={clickOverlay}
        data-testid="modalOverlay"
      />
    );

  const renderModalHeader = () => {
    if (hideCloseButton && contentLoaded) {
      return null;
    }

    return (
      <div
        className={classnames(styles.stickyContainer, styles.modalHeader)}
        ref={(node) => {
          node && setStickyHeaderHeight(node.offsetHeight);
          node && !contentLoaded && setModalRef(node);
        }}
      >
        {renderCloseButton()}
        {title && (
          <h2 id="modalTitle" className={styles.title}>
            {title}
          </h2>
        )}
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      </div>
    );
  };

  const modalStyles = () => ({
    overflowY: withScrollBar ? "scroll" : "hidden",
    scrollPaddingTop: `${stickyHeaderHeight}px`,
  });

  return shouldRender ? (
    <>
      {renderOverlay()}
      <div
        id={modalId}
        data-testid={modalType}
        className={classnames(styles.modal, {
          open,
          withDarkThemeSupport: contentLoaded && withDarkThemeSupport,
          ...getModalTypeStyle(),
        })}
        style={modalStyles()}
        ref={(node) => node && contentLoaded && setModalRef(node)}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modalTitle"
      >
        {!contentLoaded && (
          <ModalOverlay modalId={modalId}>
            <BrandedSpinner colour={"black"} />
          </ModalOverlay>
        )}
        {renderModalHeader()}
        {children}
      </div>
    </>
  ) : null;
}

Modal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  contentLoaded: PropTypes.bool.isRequired,
  focusOnClose: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node,
  modalId: PropTypes.string.isRequired,
  modalType: PropTypes.oneOf([SIDE, CENTER, CENTER_LARGE]).isRequired,
  handleOverlayClick: PropTypes.func,
  overlayOpen: PropTypes.bool,
  withScrollBar: PropTypes.bool,
  withDarkThemeSupport: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  forceDOMRewrite: PropTypes.bool,
  useFocusTrapCatchAll: PropTypes.bool,
};
