import PropTypes from "prop-types";
import { usePaymentPromoConfig, useStructuredMessage } from "./hooks";
import styles from "./PaymentPromo.scss";
import { setPaymentPromoViewed } from "../../analytics/pageLoad";

const PaymentPromo = ({ priceValue: price }) => {
  const paymentPromoConfig = usePaymentPromoConfig(price);
  const structuredMessage = useStructuredMessage(price, paymentPromoConfig);

  if (!paymentPromoConfig) {
    return null;
  }

  setPaymentPromoViewed({
    providers: paymentPromoConfig.providers,
  });

  return (
    <div className={styles.paymentPromoComponent} data-testid="payment-promo">
      <div dangerouslySetInnerHTML={{ __html: structuredMessage }} />
    </div>
  );
};

PaymentPromo.propTypes = {
  priceValue: PropTypes.number.isRequired,
};

export default PaymentPromo;
