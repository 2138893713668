import { useLooksProducts } from "../../../context/looks/LooksProductsContext";

const useLooksProductIndex = ({ selectedProductId }) => {
  const { looksProducts } = useLooksProducts();

  const looksProductIndex = looksProducts.findIndex(
    ({ id }) => id === selectedProductId
  );

  return looksProductIndex !== -1 ? looksProductIndex : null;
};

export default useLooksProductIndex;
