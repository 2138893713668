import getSdks from "../../utils/sdks";

export const addItemToBag = async ({ selectedVariant }) => {
  if (!selectedVariant) {
    return;
  }

  const bagSdk = await getSdks().bag;

  return bagSdk.addVariant({
    variantId: selectedVariant.variantId,
    seller: selectedVariant.seller,
  });
};
