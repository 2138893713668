import getWindow from "./window";

const measuredMetrics = [];

export default function setComponentPerformance({ metric }) {
  if (!measuredMetrics.includes(metric)) {
    getWindow().asos.performance.markAndMeasure(metric);
    measuredMetrics.push(metric);
  }
}
