import { useRef } from "react";
import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./PricingIcon.scss";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { useFeaturesReady } from "../../../context/featuresReady/FeaturesReadyContext";
import { getFeature } from "../../../utils/features";
import { getPricingModalUrl } from "../../../utils/getPricingModalUrl";
import {
  openIFrameModal,
  getModalTypes,
} from "../../../utils/siteChromeModals";

const classnames = classnamesBind.bind(styles);
export default function PricingIcon({
  priceData,
  firePricingModalOpen = () => {},
  setPricingIconLoad = () => {},
  isStoreEligibleForLowestPriceInLast30Days,
}) {
  const button = useRef(null);
  const { getTranslation } = useRuntimeConstants();
  const { areFeaturesReady } = useFeaturesReady();

  const showIcon =
    isStoreEligibleForLowestPriceInLast30Days ||
    (areFeaturesReady && getFeature("pdp-was-price-more-info").isEnabled());

  const getModalName = () => {
    if (isStoreEligibleForLowestPriceInLast30Days) {
      return priceData.isOutletPrice
        ? "eu_omnibus_outlet"
        : "eu_omnibus_non_outlet";
    } else {
      return priceData.isOutletPrice ? "rrp" : "was";
    }
  };

  const handleClick = (event) => {
    event.preventDefault();

    const modalName = getModalName();
    firePricingModalOpen(modalName);

    return openIFrameModal({
      titleRequired: false,
      focusOnCloseElement: button.current,
      modalType: getModalTypes()?.SIDE_RIGHT,
      url: getPricingModalUrl(modalName, priceData),
    });
  };

  if (!showIcon) {
    return null;
  }

  setPricingIconLoad();

  return (
    <button
      ref={button}
      className={styles.pricingButton}
      aria-label={getTranslation("more_pricing_info_label")}
      onClick={handleClick}
    >
      <span
        data-testid="pricing-information-icon"
        className={classnames(styles.icon, "product-information-light")}
      />
    </button>
  );
}

PricingIcon.propTypes = {
  priceData: PropTypes.shape({
    isOutletPrice: PropTypes.string,
    previous: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    startDateTime: PropTypes.string,
  }).isRequired,
  firePricingModalOpen: PropTypes.func,
  setPricingIconLoad: PropTypes.func,
  isStoreEligibleForLowestPriceInLast30Days: PropTypes.bool,
};
