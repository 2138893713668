import styles from "./VatMessage.scss";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";

export default function VatMessage() {
  const { getTranslation } = useRuntimeConstants();

  return (
    <span className={styles.vatMessage}>
      {getTranslation("pdp_misc_vat_message")}
    </span>
  );
}
