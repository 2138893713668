import { getPrimaryVariant } from "../../utils/product";
import styles from "./ProductSize.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useProduct } from "../../context/product/ProductContext";
import { PropTypes } from "prop-types";
import classnamesBind from "classnames/bind";
import { TEXT_SIZES } from "../../constants";

const classnames = classnamesBind.bind(styles);

function renderForOneSizeOrOneVariant({ isOneSize, variants }) {
  return isOneSize || (!isOneSize && variants.length === 1);
}

function shouldRenderSize(product) {
  const { isOneSize, isNoSize, variants } = product;

  return !isNoSize && renderForOneSizeOrOneVariant({ isOneSize, variants });
}

const ProductSize = ({ size = TEXT_SIZES.leavesden2 }) => {
  const { product } = useProduct();
  const { getTranslation } = useRuntimeConstants();

  if (!product) {
    return null;
  }

  return (
    shouldRenderSize(product) && (
      <div className={styles.productSize}>
        <h2 className={styles.sizeTitle}>{getTranslation("pdp_misc_size")}:</h2>
        <p
          className={classnames(styles.sizeValue, styles[size])}
          data-testid="productSize"
        >
          {getPrimaryVariant(product.variants).size}
        </p>
      </div>
    )
  );
};

ProductSize.propTypes = {
  size: PropTypes.oneOf(TEXT_SIZES),
};

export default ProductSize;
