import { useRef } from "react";
import PropTypes from "prop-types";
import Button from "../../buttons/button/Button";
import { BUTTON_TYPES } from "../../buttons/button/ButtonTypes";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import setComponentPerformance from "../../../utils/setComponentPerformance";
import { handleModalTriggerEvent } from "../utils/handleModalTriggerEvent";
import { SEE_SIMILAR_OPEN_MODAL_EVENT } from "../constants";
import useLooksProductIndex from "../utils/useLooksProductIndex";
import styles from "./SeeSimilarButton.scss";

export default function SeeSimilarButton({ selectedProductId }) {
  const seeSimilarButtonRef = useRef();
  const { getTranslation } = useRuntimeConstants();
  const looksProductIndex = useLooksProductIndex({ selectedProductId });

  setComponentPerformance({ metric: "pdp:see_similar_interactive" });

  const handleClick = () => {
    handleModalTriggerEvent({
      focusOnCloseElement: seeSimilarButtonRef.current,
      position: looksProductIndex,
      eventKey: SEE_SIMILAR_OPEN_MODAL_EVENT,
    });
  };

  return (
    <Button
      buttonRef={seeSimilarButtonRef}
      handleClick={handleClick}
      type={BUTTON_TYPES.tertiary}
      dataTestId="see-similar-button"
      ariaLabel={getTranslation("similar_items_button")}
      additionalClasses={styles.seeSimilarButton}
    >
      {getTranslation("similar_items_button")}
    </Button>
  );
}

SeeSimilarButton.propTypes = {
  selectedProductId: PropTypes.string.isRequired,
};
