const BUTTON_TYPES = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  primaryPurchase: "primaryPurchase",
  primaryPurchaseFullWidth: "primaryPurchaseFullWidth",
  secondaryPurchase: "secondaryPurchase",
  secondaryPurchaseFullWidth: "secondaryPurchaseFullWidth",
  naked: "naked",
};

export { BUTTON_TYPES };
