import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./PercentageDiscount.scss";
import {
  COMPLEMENTARY_PRIMARY_PRICE,
  PRICE_TYPES,
} from "../../priceComponents/priceTypes";
import useDiscountPercentage from "../../../hooks/useDiscountPercentage";

const classnames = classnamesBind.bind(styles);

const shouldHighlightDiscount = ({
  highlightNegativeOnly,
  percentageNumber,
}) => {
  if (highlightNegativeOnly) {
    return percentageNumber < 0;
  }
  return true;
};

export default function PercentageDiscount({
  percentageDiscountLabel,
  highlightNegativeOnly,
  style,
}) {
  const { showDiscountPercentage } = useDiscountPercentage();

  const percentageNumber = parseInt(percentageDiscountLabel);

  if (
    (!percentageNumber && percentageNumber !== 0) ||
    !showDiscountPercentage
  ) {
    return null;
  }

  return (
    <span
      aria-hidden={true}
      className={classnames(styles.percentageDiscount, {
        complementary: style === COMPLEMENTARY_PRIMARY_PRICE,
        highlight: shouldHighlightDiscount({
          highlightNegativeOnly,
          percentageNumber,
        }),
      })}
      data-testid="percentage-discount"
    >
      ({percentageDiscountLabel})
    </span>
  );
}

PercentageDiscount.propTypes = {
  percentageDiscountLabel: PropTypes.string.isRequired,
  highlightNegativeOnly: PropTypes.bool,
  style: PropTypes.oneOf(PRICE_TYPES),
};
