import { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./ShippingRestrictionsModal.scss";
import { MODAL_TYPE } from "../../constants";
import Modal from "../modal/Modal";
import { getCountryNames } from "../../utils/countryNames";
import Error from "../banner/error/Error";
import { fireShippingRestrictionsAnalytics } from "../../analytics/pageInteraction";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function ShippingRestrictionsModal({
  includedCountries,
  focusOnClose,
  toggleShippingRestrictions,
  open,
}) {
  const fetchedShippingRestrictionCountries = useRef(false);
  const analyticsFired = useRef(false);
  const [modalId] = useState(
    `${MODAL_TYPE.SIDE}${Math.floor(Math.random() * 10000)}`
  );
  const [includedCountryNames, setIncludedCountryNames] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { getTranslation } = useRuntimeConstants();

  const updateCountries = useCallback(async () => {
    const includedCountryNames = await getCountryNames(includedCountries);

    setIncludedCountryNames(includedCountryNames);
    setFilteredCountries(includedCountryNames);
    setContentLoaded(true);
  }, [includedCountries]);

  useEffect(() => {
    (async () => {
      if (
        includedCountries.length &&
        !fetchedShippingRestrictionCountries.current
      ) {
        await updateCountries();
        fetchedShippingRestrictionCountries.current = true;
      }
    })();
  }, [includedCountries, updateCountries]);

  useEffect(() => {
    if (!analyticsFired.current && open) {
      fireShippingRestrictionsAnalytics();
      analyticsFired.current = true;
    }
  }, [open]);

  const shippingRestrictionsClose = () => toggleShippingRestrictions(false);

  const handleFilterInput = (event) => {
    setFilteredCountries(
      includedCountryNames.filter((country) =>
        country.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };

  const renderRestrictions = () => {
    return includedCountryNames.length ? (
      <div
        className={styles.container}
        data-testid="shipping-restrictions-modal-content"
      >
        <label htmlFor="shippable-countries-input" className={styles.label}>
          {getTranslation("pdp_shipping_restrictions_filter_countries_label")}
        </label>
        <input
          id="shippable-countries-input"
          type="text"
          onChange={handleFilterInput}
          className={styles.filterInput}
        />
        {filteredCountries.length ? (
          <>
            <h3 className={styles.description}>
              {getTranslation("pdp_shipping_restrictions_only_ships_message")}
            </h3>
            <ul
              className={styles.countryList}
              data-testid="shipping-restrictions-country-list"
            >
              {filteredCountries.map((country) => (
                <li key={country} className={styles.country}>
                  {country}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p
            className={styles.noMatches}
            data-testid="shipping-restrictions-no-matches"
          >
            {getTranslation(
              "pdp_shipping_restrictions_error_no_matching_countries_message"
            )}
          </p>
        )}
      </div>
    ) : (
      <Error text={getTranslation("pdp_status_error_technical_difficulties")} />
    );
  };

  return (
    <Modal
      title={getTranslation("pdp_shipping_restrictions_title")}
      open={open}
      contentLoaded={contentLoaded}
      closeModal={shippingRestrictionsClose}
      focusOnClose={focusOnClose}
      modalId={modalId}
      modalType={MODAL_TYPE.SIDE}
    >
      {contentLoaded && renderRestrictions()}
    </Modal>
  );
}

ShippingRestrictionsModal.propTypes = {
  includedCountries: PropTypes.array,
  focusOnClose: PropTypes.object,
  toggleShippingRestrictions: PropTypes.func,
  open: PropTypes.bool,
};
