import PropTypes from "prop-types";
import styles from "./Errors.scss";

export default function AddToBagError({ errorMessage }) {
  return (
    <div className={styles.errorMessage} data-testid="bag-error-message">
      {errorMessage}
    </div>
  );
}

AddToBagError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};
