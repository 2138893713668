import PropTypes from "prop-types";
import styles from "./SellerInfo.scss";
import { useRef } from "react";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { openSellerInfoModal } from "./SellerInfoModal";

const SellerInfo = ({
  sellerId,
  sourceId,
  variants,
  fireSellerInfoAnalytics = () => {},
}) => {
  const button = useRef(null);
  const { getTranslation, product } = useRuntimeConstants();

  const handleClick = (event) => {
    event.preventDefault();

    fireSellerInfoAnalytics({
      variantId: variants.length === 1 ? variants[0].variantId : product.id,
      sellerId,
      sourceId,
    });

    openSellerInfoModal({
      focusOnCloseElement: button.current,
      iFrameTitle: getTranslation("seller_info_iframe_title"),
      sellerId,
      sourceId,
    });
  };

  if (!sellerId) {
    return null;
  }

  return (
    <button
      aria-label={getTranslation("seller_info_more_information")}
      data-testid="sellerInfoButton"
      className={styles.sellerInfoButton}
      onClick={handleClick}
      ref={button}
    >
      <span className="product-information-light"></span>
    </button>
  );
};

export default SellerInfo;

SellerInfo.propTypes = {
  sellerId: PropTypes.string,
  sourceId: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.object).isRequired,
  fireSellerInfoAnalytics: PropTypes.func,
};
