import { waitForPageLoadAnalytics } from "./pageLoad";
import getWindow from "../utils/window";
import {
  addMerchEVarToProducts,
  getAsosFulfilsPrefix,
  getCommons,
  getGlobalEvars,
} from "./common";
import { getFitAssistantStatus } from "./fitAssistantCommon";
import { setEvents, setLinkTrackEvents } from "./events";
import {
  getAverageOverallRating,
  getFirstSubRatingValue,
  getMostRecentRating,
  getPercentageRecommended,
  getTotalReviewCount,
} from "./ratings";
import { getProductSourceSellerInfo } from "../../utils/sourceSellerHelper";
import { getAndPersistValue } from "./getAndPersistValue";
import { isProductWithSupplierColours } from "../components/facets/utils/shouldShowColour";
import { getCommonLooksTrackingValues } from "./looks/common";

function getRatingsTracking(ratings) {
  const { event257, merchEVar189 } = getAverageOverallRating(ratings);
  const { event258, merchEVar190 } = getTotalReviewCount(ratings);
  const { event259, merchEVar191 } = getPercentageRecommended(ratings);
  const { event260, merchEVar192 } = getFirstSubRatingValue(ratings);
  const { event274, merchEVar202 } = getMostRecentRating(ratings);

  return {
    ratingsEvents: [event257, event258, event259, event260, event274].filter(
      (event) => event
    ),
    ratingsMerchEVars: [
      merchEVar189,
      merchEVar190,
      merchEVar191,
      merchEVar192,
      merchEVar202,
    ].filter((event) => event),
  };
}

function getSponsoredAdsTracking() {
  const s = getWindow().s;
  let eVar234;
  let eVar243;

  if (s.gpv_eVar234) {
    eVar234 = `eVar234=${s.gpv_eVar234}`;
  }

  if (s.eVar230) {
    eVar243 = `eVar243=${getAndPersistValue("gpv_eVar243")}`;
  }

  return [eVar234, eVar243];
}

function getFeaturedProductTracking() {
  const s = getWindow().s;
  if (s.gpv_eVar193) {
    return `eVar193=${s.gpv_eVar193}`;
  }
}

function getColourGroupingTracking(product) {
  if (
    isProductWithSupplierColours(product) &&
    product.pdpLayout !== "Face & Body"
  ) {
    return "eVar246=more colours";
  }
}

function getSourceAndSeller(selectedVariant) {
  let { sourceId, sellerId } = getProductSourceSellerInfo({
    variants: [selectedVariant],
  });

  if (!sourceId) {
    sourceId = "primary";
  }
  if (!sellerId) {
    sellerId = "asos";
  }

  return { sellerId, sourceId };
}

function getSaveCountTracking({ saveCount }) {
  if (saveCount?.isShowing) {
    return `eVar228=${saveCount.value}`;
  }
}

async function setCommonAnalytics({
  selectedVariant,
  product,
  ratings,
  sizeGuideOpened,
  trackingName,
}) {
  const s = getWindow().s;

  const { ratingsEvents, ratingsMerchEVars } = getRatingsTracking(ratings);
  const { sourceId, sellerId } = getSourceAndSeller(selectedVariant);
  const sourceSellerPrefix = getAsosFulfilsPrefix({
    sellerId,
    sourceId,
  });
  let prop36 = s.prop36;

  if (!prop36) {
    prop36 = await getFitAssistantStatus({ noFitStatus: true });
    s.prop36 = prop36;
  }

  s.eVar92 = "product page";
  s.eVar161 = `${selectedVariant.price.discountPercentage}`;

  const merchEVars = [
    `eVar6=${s.eVar6}`,
    "eVar13=product page",
    `eVar150=${prop36}`,
    product.sellingFast ? "eVar151=selling fast" : null,
    `eVar184=${sizeGuideOpened}-${product.brandName}`,
    `eVar211=${sourceSellerPrefix}${sourceId.toLowerCase()}`,
    `eVar213=${s.eVar212}`,
    `eVar215=${sourceSellerPrefix}${sellerId.toLowerCase()}`,
    getSaveCountTracking(product),
    ratingsMerchEVars.length ? ratingsMerchEVars.join("|") : undefined,
    ...getSponsoredAdsTracking().filter((eVar) => eVar),
    getColourGroupingTracking(product),
    getFeaturedProductTracking(),
  ];

  s.products = addMerchEVarToProducts(
    `;${selectedVariant.variantId}`,
    merchEVars.filter((eVar) => eVar).join("|")
  );

  setEvents({
    events: ratingsEvents,
    trackingName,
  });
}

export async function fireAddToBagAnalytics({
  selectedVariant,
  product,
  ratings,
  sizeGuideOpened,
}) {
  await waitForPageLoadAnalytics;

  const s = getWindow().s;
  const commons = getCommons();

  await setCommonAnalytics({
    selectedVariant,
    product,
    ratings,
    sizeGuideOpened,
    trackingName: "addToBag",
  });

  setEvents({
    events: [
      "scAdd",
      "scOpen",
      "event150=1",
      `event151=${selectedVariant.price.current.value}`,
    ],
    trackingName: "addToBag",
  });

  s.tl(true, "o", "add to bag", {
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "prop36",
      "eVar31",
      "eVar50",
      "eVar51",
      "eVar92",
      "eVar152",
      "eVar161",
      "eVar231",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: "addToBag" }),
  });
}

export async function fireAddToBagErrorAnalytics({
  selectedVariant,
  product,
  ratings,
  sizeGuideOpened,
  errorCode,
  errorMessage,
}) {
  await waitForPageLoadAnalytics;

  const s = getWindow().s;
  const commons = getCommons();

  await setCommonAnalytics({
    selectedVariant,
    product,
    ratings,
    sizeGuideOpened,
    trackingName: "addToBagError",
  });

  s.prop38 = `${errorMessage}|${errorCode}`;

  setEvents({
    events: ["event149"],
    trackingName: "addToBagError",
  });

  s.tl(true, "o", "add to bag error", {
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "prop36",
      "prop38",
      "eVar31",
      "eVar50",
      "eVar51",
      "eVar92",
      "eVar152",
      "eVar161",
      "eVar231",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: "addToBagError" }),
  });
}

export function fireLooksAddToBagAnalytics(looksProducts) {
  return async ({ selectedVariant }) => {
    await waitForPageLoadAnalytics;

    const s = getWindow().s;
    const commons = getCommons();

    const commonLooksTracking = getCommonLooksTrackingValues({
      products: looksProducts,
    });

    setEvents({
      events: [
        "scAdd",
        "scOpen",
        "event150=1",
        `event151=${selectedVariant.price.current.value}`,
      ],
      trackingName: "addToBagLooks",
    });

    s.products = `;${selectedVariant.variantId}`;

    s.tl(true, "o", "add to bag", {
      ...commonLooksTracking,
      linkTrackVars: commons.setLinkTrackVars([
        ...getGlobalEvars(),
        ...Object.keys(commonLooksTracking),
      ]),
      linkTrackEvents: setLinkTrackEvents({ trackingName: "addToBagLooks" }),
    });
  };
}
