import getRuntimeConstants from "../../runtimeConstants";

export const fireMarketingEvent = (eventName, data) => {
  const {
    browseCountry,
    browseCurrency,
    browseLanguage,
    keyStoreDataversion,
    browseSizeSchema,
    templateVersion,
  } = getRuntimeConstants().webContext;

  const commonConfig = {
    keyStoreDataVersion: keyStoreDataversion,
    selectedCountry: browseCountry,
    selectedCurrency: browseCurrency,
    selectedLanguage: browseLanguage,
    selectedSizeSchema: browseSizeSchema,
    sitechromeTemplateVersion: templateVersion,
  };

  window.asos.eventBus.emit("tracking.productPage." + eventName, {
    ...data,
    ...commonConfig,
  });
};
