import { useEffect, useRef } from "react";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./VariantSelector.scss";
import {
  getInStockVariants,
  getVariantType,
} from "../../../utils/variantUtils";
import { VARIANT_TYPE } from "../../constants";
import {
  areAllInStockVariantsTheSamePrice,
  areAllOOSVariantsTheSamePrice,
} from "../../utils/discountPriceData";
import { useProduct } from "../../context/product/ProductContext";
import { useVariant } from "../../context/variant/VariantContext";
import SizeAndFitContainer from "../sizeAndFitContainer/SizeAndFitContainer";
import PropTypes from "prop-types";
import eventBusEmitter from "../../utils/eventBusEmitter";
import {
  getPrimaryVariant,
  shouldDisplayVariantSelectBasedOnVariants,
  productHasMultipleColours,
} from "../../utils/product";
import setComponentPerformance from "../../utils/setComponentPerformance";
import useEventBus from "../../hooks/useEventBus";

const getPlaceholderText = ({ variants, variantType, getTranslation }) => {
  const label =
    variantType === VARIANT_TYPE.size
      ? getTranslation("pdp_select_size_label")
      : getTranslation("pdp_select_colour_from_label", {
          count: variants.length,
        });
  return (
    <option key={variantType} value="">
      {label}
    </option>
  );
};

const addVariantPrice = ({ variants, variant }) => {
  const displayInStockPrice = areAllInStockVariantsTheSamePrice({ variants })
    ? ""
    : ` - ${variant.price.current.text}`;

  const displayOOSPrice = areAllOOSVariantsTheSamePrice({ variants })
    ? ""
    : ` - ${variant.price.current.text}`;

  return variant.isInStock ? displayInStockPrice : displayOOSPrice;
};

const addOutOfStockLabel = ({ variant, getTranslation }) =>
  variant.isInStock ? "" : ` - ${getTranslation("pdp_status_out_of_stock")}`;

const sortVariants = ({ variants }) => {
  return variants.sort((currentVariant, nextVariant) => {
    const colourComparisonResult = currentVariant.colour.localeCompare(
      nextVariant.colour,
      undefined,
      {
        numeric: true,
      }
    );

    return colourComparisonResult === 0
      ? currentVariant.sizeOrder - nextVariant.sizeOrder
      : colourComparisonResult;
  });
};

const populateVariantSelector = ({
  variants,
  variantType,
  getTranslation,
  oosVariantsAreSelectable,
}) => {
  return [
    getPlaceholderText({ variants, variantType, getTranslation }),
    ...sortVariants({ variants }).map((variant, index) => {
      return (
        <option
          key={variant.variantId}
          value={variant.variantId}
          disabled={!(oosVariantsAreSelectable || variant.isInStock)}
          data-testid={`${variantType}-${index}`}
        >
          {`${variant[variantType]}${addVariantPrice({
            variants,
            variant,
          })}${addOutOfStockLabel({ variant, getTranslation })}`}
        </option>
      );
    }),
  ];
};

const getLabelText = (variantType, getTranslation) => {
  return variantType === VARIANT_TYPE.size
    ? `${getTranslation("pdp_misc_size")}:`
    : `${getTranslation("pdp_misc_colour")}:`;
};

const updateProductImage = (selectedVariant, product, variants) => {
  if (productHasMultipleColours(product)) {
    const variant =
      selectedVariant?.colourWayId || getPrimaryVariant(variants).colourWayId;
    eventBusEmitter("setProductImage", variant);
  }
};

const shouldRenderVariantSelector = ({
  isInStock,
  product,
  oosVariantsAreSelectable,
}) => {
  return (
    shouldDisplayVariantSelectBasedOnVariants(
      product,
      oosVariantsAreSelectable
    ) &&
    (oosVariantsAreSelectable || isInStock) &&
    !!product
  );
};

const getVariants = ({ product, variantType, oosVariantsAreSelectable }) =>
  product
    ? variantType === VARIANT_TYPE.size || oosVariantsAreSelectable
      ? product.variants
      : getInStockVariants(product.variants)
    : [];

export default function VariantSelector({
  isNoVariantSelectionError,
  setShowNoVariantSelectionError,
  isInStock,
  variantSelectorId = "variantSelector",
}) {
  const { product } = useProduct();
  const { selectedVariantId, setSelectedVariantId, selectedVariant } =
    useVariant();
  const { getTranslation, oosVariantsAreSelectable } = useRuntimeConstants();
  const variantSelectorRef = useRef();
  const variantType = getVariantType({ product });
  const variants = getVariants({
    product,
    variantType,
    oosVariantsAreSelectable,
  });

  const showVariantSelector = shouldRenderVariantSelector({
    oosVariantsAreSelectable,
    isInStock,
    product,
  });

  useEffect(() => {
    if (!variantSelectorRef) {
      return;
    }

    if (isNoVariantSelectionError) {
      variantSelectorRef.current?.focus();
    }
  }, [isNoVariantSelectionError]);

  useEffect(() => {
    if (product) {
      updateProductImage(selectedVariant, product, product.variants);
    }
  }, [product, selectedVariant]);

  useEventBus(
    "GalleryInitialised",
    () => {
      if (product) {
        updateProductImage(selectedVariant, product, product.variants);
      }
    },
    [product, selectedVariant]
  );

  if (!showVariantSelector) {
    return null;
  }

  setComponentPerformance({ metric: "pdp:size_selector_interactive" });

  return (
    <div data-testid="variant-selector">
      <div className={styles.sizingHelp}>
        <label htmlFor={variantSelectorId} id={styles.label}>
          {getLabelText(variantType, getTranslation)}
        </label>
        <SizeAndFitContainer />
      </div>
      <div className={styles.variantSelectorContainer}>
        <select
          id={variantSelectorId}
          ref={variantSelectorRef}
          required={isNoVariantSelectionError}
          value={selectedVariantId ? `${selectedVariantId}` : ""}
          onChange={({ target }) => {
            setSelectedVariantId(target?.value ? parseInt(target.value) : null);
            if (target?.value) {
              setShowNoVariantSelectionError(false);
            }
          }}
        >
          {populateVariantSelector({
            variants,
            variantType,
            getTranslation,
            oosVariantsAreSelectable,
          })}
        </select>
      </div>
    </div>
  );
}

VariantSelector.propTypes = {
  isNoVariantSelectionError: PropTypes.bool.isRequired,
  setShowNoVariantSelectionError: PropTypes.func.isRequired,
  isInStock: PropTypes.bool,
  variantSelectorId: PropTypes.string,
};
