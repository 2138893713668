import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useProduct } from "../product/ProductContext";
import useFitAssistant from "../../components/fitAssistant/useFitAssistant";

const SizingContext = createContext({});

export const SizingContextProvider = ({ children }) => {
  const { product } = useProduct();
  const [sizeGuideUrl, setSizeGuideUrl] = useState(null);
  const [sizeGuideOpened, setSizeGuideOpened] = useReducer(() => true, false);

  const {
    fitAssistantLinkText,
    handleFitAssistantLinkClick,
    showFitIcon,
    recommendedSize,
    outOfScale,
    outOfStock,
    isPartialRecommendation,
    usedPastPurchases,
    category,
    invalidFitProduct,
  } = useFitAssistant();

  useEffect(() => {
    if (product?.sizeGuideVisible) {
      setSizeGuideUrl(product.sizeGuide);
    }
  }, [product]);

  return (
    <SizingContext.Provider
      value={{
        sizeGuideUrl,
        fitAssistantLinkText,
        handleFitAssistantLinkClick,
        showFitIcon,
        recommendedSize,
        outOfScale,
        outOfStock,
        isPartialRecommendation,
        usedPastPurchases,
        invalidFitProduct,
        category,
        sizeGuideOpened,
        setSizeGuideOpened,
      }}
    >
      {children}
    </SizingContext.Provider>
  );
};

export const useSizing = () => useContext(SizingContext);

SizingContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
