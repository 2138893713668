export const getSubRatingIcon = (id) => {
  const knownId = {
    Comfort: "product-rating-comfort",
    Comfort_1: "product-rating-comfort",
    Fit: "product-rating-fit",
    Fit_22: "product-rating-fit",
    Size: "product-rating-fit",
    Quality: "product-rating-quality",
    Quality_1: "product-rating-quality",
    Value: "product-rating-value",
    Value_1: "product-rating-value",
  }[id];

  return knownId ? knownId : "product-rating-fallback";
};
