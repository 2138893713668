import { useEffect } from "react";
import getWindow from "../utils/window";

export default function useEventListener(eventName, callback, dependencies) {
  useEffect(() => {
    getWindow().addEventListener(eventName, callback);

    return () => {
      getWindow() && getWindow().removeEventListener(eventName, callback);
    };
  }, dependencies);
}
