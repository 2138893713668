import getConstants from "../runtimeConstants";
import { SIMPLE_MODAL_URL } from "../constants";

export const getPricingModalUrl = (modalName, priceData) => {
  const { webContext } = getConstants();

  const areAllQueryParametersPresent = () =>
    webContext?.browseLanguage && priceData && modalName;

  if (areAllQueryParametersPresent()) {
    const { browseLanguage } = webContext;

    const formatDate = (date) => {
      const newDate = new Date(date);
      return encodeURIComponent(
        newDate.toLocaleString(browseLanguage, {
          month: "long",
          year: "numeric",
        })
      );
    };
    return `${SIMPLE_MODAL_URL}?contentTemplate=${modalName}_pricing_info&lang=${browseLanguage}&consumer=pdp&price=${encodeURIComponent(
      priceData.previous.text
    )}&date=${formatDate(priceData.previousEndDate)}`;
  }
  return null;
};
