import getDeliveryOptions from "../api/deliveryOptionsApi";

export async function getCountryNames(countryCodes) {
  const { countries } = await getDeliveryOptions();

  if (!countries) {
    return [];
  }

  return countries
    .filter(({ code }) => countryCodes.includes(code))
    .map(({ name }) => name);
}
