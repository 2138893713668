import { formatPrice } from "asos-web-currency-formatter";
import paymentProviderInstalments from "asos-web-payment-provider-instalments";
import styles from "../PaymentPromo.scss";
import getRuntimeConstants from "../../../runtimeConstants";

export const sanitizeText = (text) => {
  const tempElement = document?.createElement("div");
  tempElement.innerHTML = text;
  return tempElement.textContent;
};

const createLogoLink = ({ providerName, logoClass, informationUrl }) => {
  return `<a
      href=${informationUrl}
      target="_blank"
      rel="no-opener no-referrer noreferrer"
    >
      <span role="img" aria-label=${providerName} class='${styles[logoClass]} product-${logoClass}'></span>
    </a>`;
};

const getInstalment = (providerConfig, price, webContext) => {
  const instalment = paymentProviderInstalments[
    providerConfig.instalmentSplitType
  ](providerConfig.numberOfInstalments, price);

  return formatPrice(
    instalment,
    webContext.browseLanguage,
    webContext.browseCurrency
  );
};

const makeSinglePromoMessage = ({ config, price, webContext }) => {
  const providerConfig = config.providerConfigs[config.providers[0]];
  return config.mainCopy
    .replace("%{instalmentCount}", providerConfig.numberOfInstalments)
    .replace("%{instalment}", getInstalment(providerConfig, price, webContext))
    .replace("%{provider}", createLogoLink(providerConfig))
    .concat(config.appendedCopy);
};

const makeMultiPromoMessage = ({ config, price, webContext }) => {
  const firstProviderConfig = config.providerConfigs[config.providers[0]];
  const secondProviderConfig = config.providerConfigs[config.providers[1]];

  if (
    firstProviderConfig.numberOfInstalments ===
    secondProviderConfig.numberOfInstalments
  ) {
    return config.mainCopy
      .replace("%{instalmentCount}", firstProviderConfig.numberOfInstalments)
      .replace(
        "%{instalment}",
        getInstalment(firstProviderConfig, price, webContext)
      )
      .replace("%{firstProvider}", createLogoLink(firstProviderConfig))
      .replace("%{secondProvider}", createLogoLink(secondProviderConfig))
      .concat(config.appendedCopy);
  } else {
    return config.mainCopy
      .replace(
        "%{firstInstalmentCount}",
        firstProviderConfig.numberOfInstalments
      )
      .replace(
        "%{firstInstalment}",
        getInstalment(firstProviderConfig, price, webContext)
      )
      .replace("%{firstProvider}", createLogoLink(firstProviderConfig))
      .replace(
        "%{secondInstalmentCount}",
        secondProviderConfig.numberOfInstalments
      )
      .replace(
        "%{secondInstalment}",
        getInstalment(secondProviderConfig, price, webContext)
      )
      .replace("%{secondProvider}", createLogoLink(secondProviderConfig))
      .concat(config.appendedCopy);
  }
};

export const getPaymentPromoMessage = ({
  paymentPromoConfig,
  price,
  webContext,
}) => {
  if (!paymentPromoConfig) {
    return;
  }
  const sanitizedConfig = {
    ...paymentPromoConfig,
    mainCopy: sanitizeText(paymentPromoConfig.mainCopy),
  };

  return paymentPromoConfig.providers.length > 1
    ? makeMultiPromoMessage({ config: sanitizedConfig, price, webContext })
    : makeSinglePromoMessage({ config: sanitizedConfig, price, webContext });
};

const isValidProviderForPrice = ({
  provider,
  countryCode,
  currencyCode,
  price,
  paymentPromotions,
}) => {
  const selectedProvider =
    paymentPromotions[provider]?.[countryCode]?.[currencyCode];

  if (!selectedProvider) {
    return false;
  }

  return (
    price >= selectedProvider.minimumTransactionAmount &&
    price <= selectedProvider.maximumTransactionAmount
  );
};

export const isValidTransactionAmount = ({ paymentPromoProviders, price }) => {
  const {
    webContext: { browseCountry, browseCurrency },
    product,
  } = getRuntimeConstants();
  const countryCode = browseCountry.toLowerCase();
  const currencyCode = browseCurrency.toLowerCase();
  const paymentPromotions = product.paymentPromotions;

  if (!Object.entries(paymentPromotions).length) {
    return false;
  }

  if (paymentPromoProviders?.length > 1) {
    let isValid = true;

    paymentPromoProviders.forEach((provider) => {
      isValid = isValid
        ? isValidProviderForPrice({
            provider,
            countryCode,
            currencyCode,
            price,
            paymentPromotions,
          })
        : false;
    });

    return isValid;
  }

  if (paymentPromoProviders?.length === 1) {
    const provider = paymentPromoProviders[0];

    return isValidProviderForPrice({
      provider,
      countryCode,
      currencyCode,
      price,
      paymentPromotions,
    });
  }

  return false;
};
