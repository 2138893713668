import { useCallback, useEffect, useRef, useState } from "react";
import { useProduct } from "../../context/product/ProductContext";
import { getPrimaryImage, productHasMultipleSizes } from "../../utils/product";
import getWindow from "../../utils/window";
import { getCustomerId } from "../../utils/identity";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { usePrivacy } from "../../context/privacy/PrivacyContext";
import { ERROR_TYPE, logError } from "../../utils/newRelicLogger";
import { fitAssistantLoggingPercent } from "../../constants";
import {
  ignoreFitAssistantAnalytics,
  setFitAssistantAnalytics,
} from "../../analytics/postPageLoad/fitAssistant";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import {
  fireCloseFitAssistantAnalytics,
  fireOpenFitAssistantAnalytics,
  fireOutOfScaleFitAssistantAnalytics,
} from "../../analytics/fitAssistant";
import { NEW_USER } from "../../analytics/fitAssistantCommon";

function appendFitAssistantScript() {
  const script = getWindow().document.createElement("script");
  script.id = "fitAnalyticsScript";
  script.src = "https://widget.fitanalytics.com/widget.js";
  script.crossOrigin = "anonymous";
  script.async = true;
  getWindow().document.body.appendChild(script);
}

function getShopCountry({ storeCode }) {
  switch (storeCode) {
    case "COM":
      return "GB";
    case "ROW":
      return "ASOS_ROW";
    case "ROE":
      return "ASOS_ROE";
    default:
      return storeCode;
  }
}

function formatSize({ size }) {
  return size.split("-")[0].replace(/\s+$/, "");
}

function getSizes({ variants }) {
  const formattedSizes = {};

  for (const variant of variants) {
    formattedSizes[formatSize(variant)] = variant.isInStock;
  }

  return formattedSizes;
}

function getFitAssistantState({
  getTranslation,
  recommendedSize,
  outOfScale,
  outOfStock,
  isPartialRecommendation,
}) {
  if (recommendedSize === NEW_USER || outOfScale) {
    return {
      fitAssistantLinkText: getTranslation(
        "pdp_fit_analytics_recommendation_profile_none"
      ),
      showIcon: true,
    };
  }
  if (outOfStock) {
    return {
      fitAssistantLinkText: getTranslation(
        "pdp_fit_analytics_recommendation_oos",
        {
          size: recommendedSize,
        }
      ),
      showIcon: false,
    };
  }
  return {
    fitAssistantLinkText: getTranslation(
      isPartialRecommendation
        ? "pdp_fit_analytics_recommendation_profile_partial"
        : "pdp_fit_analytics_recommendation_profile_complete",
      { size: recommendedSize }
    ),
    showIcon: false,
  };
}

function shouldLogError() {
  return Math.random() * 100 <= fitAssistantLoggingPercent;
}

function shouldShowFit({
  customerPreferences,
  product,
  fitAssistantWidget,
  fitAssistantEnabled,
}) {
  return (
    fitAssistantEnabled &&
    !fitAssistantWidget &&
    customerPreferences &&
    product &&
    productHasMultipleSizes(product)
  );
}

export default function useFitAssistant() {
  const { customerPreferences } = usePrivacy();
  const { product } = useProduct();
  const { isInStock } = useIsInStockContext();
  const {
    webContext,
    isMobile,
    getTranslation,
    isMixAndMatch,
    fitAssistantEnabled,
  } = useRuntimeConstants();
  const [fitAssistantWidget, setFitAssistantWidget] = useState(null);
  const [fitAssistantLinkText, setFitAssistantLinkText] = useState(null);
  const [showFitIcon, setShowFitIcon] = useState(false);

  const storedRecommendedSize = useRef(null);
  const storedOutOfScale = useRef(null);
  const storedIsPartialRecommendation = useRef(null);
  const storedUsedPastPurchases = useRef(null);
  const storedCategory = useRef(null);
  const storedOutOfStock = useRef(null);
  const storedInvalidFitProduct = !productHasMultipleSizes(product);

  const focusOnClose = useRef();

  useEffect(() => {
    if (isMixAndMatch || isInStock === false || !fitAssistantEnabled) {
      ignoreFitAssistantAnalytics();
    }
  }, [isMixAndMatch, isInStock, fitAssistantEnabled]);

  useEffect(() => {
    if (
      !shouldShowFit({
        customerPreferences,
        product,
        fitAssistantWidget,
        fitAssistantEnabled,
      })
    ) {
      if (
        customerPreferences === false ||
        (product && !productHasMultipleSizes(product))
      ) {
        setFitAssistantAnalytics({
          invalidFitProduct: storedInvalidFitProduct,
          customerPreferences,
          storeCode: webContext.storeId,
        });
      }
      return;
    }

    const updateRecommendation = ({
      recommendedSize,
      outOfScale,
      outOfStock,
      isPartialRecommendation,
      usedPastPurchases,
      category,
    }) => {
      const { fitAssistantLinkText, showIcon } = getFitAssistantState({
        getTranslation,
        recommendedSize,
        outOfScale,
        outOfStock,
        isPartialRecommendation,
      });

      storedRecommendedSize.current = recommendedSize;
      storedOutOfScale.current = outOfScale;
      storedIsPartialRecommendation.current = isPartialRecommendation;
      storedUsedPastPurchases.current = usedPastPurchases;
      storedCategory.current = category;
      storedOutOfStock.current = outOfStock;

      setFitAssistantLinkText(fitAssistantLinkText);
      setShowFitIcon(showIcon);
    };

    (async () => {
      const customerId = await getCustomerId();
      let recordMetrics = false;
      let metrics = [];

      //eslint-disable-next-line no-underscore-dangle
      getWindow()._fitAnalytics = () => {
        const FitAnalyticsWidget = getWindow().FitAnalyticsWidget;
        const widget = new FitAnalyticsWidget({
          productId: `asos-${product.productCode}`,
          thumb: getPrimaryImage(product.images)?.url,
          manufacturedSizes: getSizes(product),
          userId: customerId,
          language: webContext.browseLanguage.substr(0, 2),
          shopCountry: getShopCountry({ storeCode: webContext.storeId }),
          shopSizingSystem: webContext.browseSizeSchema,
          prefetch: true,
          showMobile: isMobile,
          close: (
            productSerial,
            recommendedSize,
            {
              outOfScale,
              outOfStock,
              isPartialRecommendation,
              usedPastPurchases,
              category,
            }
          ) => {
            if (recommendedSize) {
              updateRecommendation({
                recommendedSize,
                outOfScale,
                outOfStock,
                isPartialRecommendation,
                usedPastPurchases,
                category,
              });
              fireCloseFitAssistantAnalytics({
                recommendedSize,
                outOfScale,
                isPartialRecommendation,
                usedPastPurchases,
                category,
                customerPreferences,
                outOfStock,
                storeCode: webContext.storeId,
                metrics,
                product,
              });
            } else {
              fireCloseFitAssistantAnalytics({
                recommendedSize: storedRecommendedSize.current,
                outOfScale: storedOutOfScale.current,
                isPartialRecommendation: storedIsPartialRecommendation.current,
                usedPastPurchases: storedUsedPastPurchases.current,
                category: storedCategory.current,
                customerPreferences,
                outOfStock: storedOutOfStock.current,
                storeCode: webContext.storeId,
                metrics,
                product,
              });
            }
            if (outOfScale && recommendedSize !== NEW_USER) {
              fireOutOfScaleFitAssistantAnalytics({
                recommendedSize,
                outOfScale,
                isPartialRecommendation,
                usedPastPurchases,
                category,
                customerPreferences,
                outOfStock,
                storeCode: webContext.storeId,
                metrics,
                product,
              });
            }
            recordMetrics = false;
            focusOnClose.current?.focus();
          },
          open: () => {
            metrics = [];
            recordMetrics = true;
            fireOpenFitAssistantAnalytics({
              recommendedSize: storedRecommendedSize.current,
              outOfScale: storedOutOfScale.current,
              isPartialRecommendation: storedIsPartialRecommendation.current,
              usedPastPurchases: storedUsedPastPurchases.current,
              category: storedCategory.current,
              customerPreferences,
              outOfStock: storedOutOfStock.current,
              storeCode: webContext.storeId,
              metrics,
              product,
            });
          },
          metricEvent: (event, value) => {
            if (recordMetrics) {
              metrics.push({ event, value });
            }
          },
          error: (productSerial, { code, message }) => {
            setFitAssistantAnalytics({
              invalidFitProduct: true,
              customerPreferences,
              storeCode: webContext.storeId,
            });
            if (
              code === "product_not_published" ||
              code === "product_not_found"
            ) {
              return;
            }

            if (shouldLogError()) {
              logError(ERROR_TYPE.FitAssistantError, {
                productSerial,
                errorCode: code,
                errorMessage: message,
              });
            }
          },
          recommend: (
            productSerial,
            recommendedSize,
            {
              isProductUnsupported,
              outOfScale,
              outOfStock,
              isPartialRecommendation,
              usedPastPurchases,
              category,
            }
          ) => {
            setFitAssistantAnalytics({
              recommendedSize,
              outOfScale,
              isPartialRecommendation,
              isProductUnsupported,
              usedPastPurchases,
              category,
              customerPreferences,
              outOfStock,
              storeCode: webContext.storeId,
              product,
            });
            if (isProductUnsupported) {
              return;
            }
            updateRecommendation({
              recommendedSize,
              outOfScale,
              outOfStock,
              isPartialRecommendation,
              usedPastPurchases,
              category,
            });
            if (outOfScale && recommendedSize !== NEW_USER) {
              fireOutOfScaleFitAssistantAnalytics({
                recommendedSize,
                outOfScale,
                isPartialRecommendation,
                usedPastPurchases,
                category,
                customerPreferences,
                outOfStock,
                storeCode: webContext.storeId,
                product,
              });
            }
          },
          load: () => {
            widget.getRecommendation();
          },
        });

        setFitAssistantWidget(widget);
      };

      appendFitAssistantScript();
    })();
  }, [
    customerPreferences,
    product,
    isMobile,
    webContext,
    getTranslation,
    fitAssistantWidget,
  ]);

  const handleFitAssistantLinkClick = useCallback(
    ({ target }) => {
      focusOnClose.current = target;
      fitAssistantWidget?.open();
    },
    [fitAssistantWidget]
  );

  return {
    fitAssistantLinkText,
    handleFitAssistantLinkClick,
    showFitIcon,
    recommendedSize: storedRecommendedSize.current,
    outOfScale: storedOutOfScale.current,
    isPartialRecommendation: storedIsPartialRecommendation.current,
    usedPastPurchases: storedUsedPastPurchases.current,
    category: storedCategory.current,
    outOfStock: storedOutOfStock.current,
    invalidFitProduct: storedInvalidFitProduct,
  };
}
