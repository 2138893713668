export const PRIMARY_PRICE = "PRIMARY_PRICE";
export const SECONDARY_PRICE = "SECONDARY_PRICE";
export const GRID_PRICE = "GRID_PRICE";
export const COMPLEMENTARY_PRIMARY_PRICE = "COMPLEMENTARY_PRIMARY_PRICE";

export const PRICE_TYPES = [
  PRIMARY_PRICE,
  SECONDARY_PRICE,
  GRID_PRICE,
  COMPLEMENTARY_PRIMARY_PRICE,
];
