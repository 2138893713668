import { useEffect, useState } from "react";
import SaveForLaterButton from "../../components/buttons/saveForLaterButton/SaveForLaterButton";
import saveForLater from "../../utils/saveForLater";
import { PAGE_AREAS } from "../../constants";
import { getPrimaryVariant } from "../../utils/product";
import { useVariant } from "../../context/variant/VariantContext";
import { useProduct } from "../../context/product/ProductContext";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import setComponentPerformance from "../../utils/setComponentPerformance";
import { fireSaveForLaterMarketing } from "../../utils/marketingTracking/marketingTrackingEvents";
import isRestockingSoon from "../../utils/restockingSoon";

export default function SaveForLaterContainer() {
  const { selectedVariantId, selectedVariant } = useVariant();
  const { product } = useProduct();
  const { isInStock } = useIsInStockContext();

  const [variantIdentifier, setVariantIdentifier] = useState();
  const [colourWayId, setColourWayId] = useState();

  const shouldRenderSaveForLater =
    !!product && (isInStock || isRestockingSoon(product));

  useEffect(() => {
    if (!shouldRenderSaveForLater) {
      return;
    }

    setVariantIdentifier(`variantId:${selectedVariantId}`);

    if (!selectedVariantId) {
      setColourWayId(getPrimaryVariant(product.variants).colourWayId);
      const defaultVariantIdentifier = `colourWayId:${colourWayId}`;
      setVariantIdentifier(defaultVariantIdentifier);
    }
  }, [product, selectedVariantId, colourWayId, isInStock]);

  if (!shouldRenderSaveForLater) {
    return null;
  }

  const handleSaveClick = () => {
    saveForLater({
      product,
      items: [
        {
          colourWayId: !selectedVariantId ? colourWayId : undefined,
          variantId: selectedVariantId ? selectedVariantId : undefined,
          productId: product.id,
        },
      ],
      pageArea: PAGE_AREAS.MAIN,
      sellingFast: product.sellingFast,
    });
    fireSaveForLaterMarketing(
      product,
      selectedVariant ? [selectedVariant] : product.variants
    );
  };

  setComponentPerformance({ metric: "pdp:save_for_later_interactive" });

  return (
    <SaveForLaterButton
      handleClick={handleSaveClick}
      saveForLaterClass="singleProductSaveForLater"
      variantIdentifier={variantIdentifier}
      productTitle={product.name}
    />
  );
}
