import getWindow from "../window";

export default function scrollTo(
  elementToScrollToId,
  event,
  scrollableContainerSelector
) {
  const supportsNativeSmoothScroll =
    "scrollBehavior" in getWindow().document.documentElement.style;
  const getElement = getWindow().document.getElementById(elementToScrollToId);
  const getScrollableContainer = scrollableContainerSelector
    ? getWindow().document.querySelector(scrollableContainerSelector)
    : getWindow();
  const elementTop = getElement.getBoundingClientRect().top;
  const top = scrollableContainerSelector
    ? elementTop
    : elementTop + getScrollableContainer.pageYOffset;
  event.preventDefault();
  if (supportsNativeSmoothScroll) {
    getScrollableContainer.scrollTo({
      top,
      behavior: "smooth",
    });
  } else {
    getScrollableContainer.scrollTo(0, top);
  }
  setTimeout(function () {
    getElement.focus();
  }, 500);
}
