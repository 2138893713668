import {
  getCommons,
  getProductAreaAttribute,
  addMerchEVarToProducts,
  getGlobalEvars,
} from "./common";
import { getCommonLooksTrackingValues } from "./looks/common";
import getConstants from "../runtimeConstants";
import { PAGE_AREAS } from "../constants";
import { waitForPageLoadAnalytics, isShowingSaveCount } from "./pageLoad";
import {
  getAverageOverallRating,
  getTotalReviewCount,
  hasRatings,
} from "./ratings";
import getWindow from "../utils/window";

const addCategoryPageCarouselAnalytics = () => {
  const s = getWindow().s;

  if (s.gpv_eVar234) {
    s.products = addMerchEVarToProducts(s.products, `eVar234=${s.gpv_eVar234}`);
  }
};

const saveCountTracker = ({ s, product }) => {
  if (isShowingSaveCount()) {
    s.products = addMerchEVarToProducts(
      s.products,
      `eVar228=${product.saveCount.value}`
    );
  }
};

const addRatingsEvents = ({ ratings, trackEvents, isMixAndMatch }) => {
  if (!hasRatings(ratings) || isMixAndMatch) {
    return;
  }

  const { event257 } = getAverageOverallRating(ratings);
  const { event258 } = getTotalReviewCount(ratings);

  trackEvents.push(event257);
  trackEvents.push(event258);
};

const addProductAreaAttribute = ({ pageArea }) => {
  const s = getWindow().s;

  const productAreaAttribute = getProductAreaAttribute(pageArea);

  if (productAreaAttribute) {
    s.products = addMerchEVarToProducts(
      s.products,
      `eVar13=${productAreaAttribute}`
    );
    s.eVar92 = productAreaAttribute;
  }
};

const getEvar60 = (pageArea) => {
  return {
    [PAGE_AREAS.LOOKS]: "buy the look - btl product",
    [PAGE_AREAS.LOOKS_SIMILAR_ITEMS]: "buy the look - see similar product",
  }[pageArea];
};

const addRestockingSoonAnalytics = ({ s, product }) => {
  if (product?.restockingSoonDetails.shouldShowRestockingSoon) {
    s.products = addMerchEVarToProducts(s.products, "eVar236=restocking soon");
  }
};

export default async function fireSaveForLaterAnalytics(
  product,
  pageArea,
  looksProducts
) {
  await waitForPageLoadAnalytics;

  const { isMixAndMatch, ratings } = getConstants();

  const commons = getCommons();
  const trackVars = ["eVar92", "eVar185", "eVar186"];
  const trackEvents = ["event10"];
  const s = getWindow().s;
  let overrides = {};

  s.products = `;${product.id}`;

  addProductAreaAttribute({ pageArea, productId: product.id });

  if (pageArea === PAGE_AREAS.MAIN) {
    if (product?.sellingFast) {
      s.products = addMerchEVarToProducts(s.products, "eVar151=selling fast");
    }

    addRestockingSoonAnalytics({ s, product });
    addCategoryPageCarouselAnalytics();
    addRatingsEvents({ ratings, trackEvents, isMixAndMatch });

    saveCountTracker({
      s,
      product,
    });

    if (isMixAndMatch) {
      s.products = addMerchEVarToProducts(s.products, "eVar182=mix & match");
      s.eVar181 = "mix & match";
      trackVars.push("eVar181");
    }
  }

  if ([PAGE_AREAS.LOOKS, PAGE_AREAS.LOOKS_SIMILAR_ITEMS].includes(pageArea)) {
    const commonLooksTracking = getCommonLooksTrackingValues({
      products: looksProducts,
    });

    overrides = {
      ...overrides,
      ...commonLooksTracking,
      eVar60: getEvar60(pageArea),
      eVar61: getEvar60(pageArea),
    };

    trackVars.push(...Object.keys(commonLooksTracking));
  }

  s.events = trackEvents.join(",");

  overrides = {
    ...overrides,
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      ...trackVars,
    ]),
    linkTrackEvents: commons.setLinkTrackEvents([
      ...trackEvents.map((event) => event.split("=")[0]),
    ]),
  };

  s.tl(true, "o", "save for later click", overrides);
}
