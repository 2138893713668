import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./CurrentPrice.scss";
import {
  PRICE_TYPES,
  PRIMARY_PRICE,
  SECONDARY_PRICE,
  GRID_PRICE,
  COMPLEMENTARY_PRIMARY_PRICE,
} from "../priceTypes";
import VatMessage from "../vatMessage/VatMessage";

const classnames = classnamesBind.bind(styles);

export default function CurrentPrice({
  currentPriceText,
  priceStyle = PRIMARY_PRICE,
  showVatMessage,
  isSalePrice,
}) {
  return (
    <>
      <span
        aria-hidden={true}
        data-testid="current-price"
        className={classnames({
          primaryPrice: priceStyle === PRIMARY_PRICE,
          secondaryPrice: priceStyle === SECONDARY_PRICE,
          gridPrice: priceStyle === GRID_PRICE,
          complementaryPrimaryPrice: priceStyle === COMPLEMENTARY_PRIMARY_PRICE,
          isSalePrice,
        })}
      >
        {currentPriceText}
      </span>
      {showVatMessage && <VatMessage />}
    </>
  );
}

CurrentPrice.propTypes = {
  currentPriceText: PropTypes.string.isRequired,
  priceStyle: PropTypes.oneOf(PRICE_TYPES),
  showVatMessage: PropTypes.bool,
  isSalePrice: PropTypes.bool,
};
