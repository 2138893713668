import getWindow from "../../../utils/window";

export const handleModalTriggerEvent = ({
  focusOnCloseElement,
  position,
  eventKey,
}) => {
  getWindow().asos.eventBus.emit(eventKey, {
    focusOnCloseElement,
    position,
  });
};
