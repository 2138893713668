import PropTypes from "prop-types";

import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./OutOfStockLabel.scss";
import { VARIANT_TYPE } from "../../constants";

export default function VariantOutOfStockLabel({ variantType }) {
  const { getTranslation } = useRuntimeConstants();

  return (
    <h3
      id={styles.oosLabelText}
      data-testid={`${variantType}OosLabel`}
      className={styles.looksProductTextLabel}
    >
      {variantType === VARIANT_TYPE.size
        ? `${getTranslation("pdp_size_out_of_stock")}`
        : `${getTranslation("pdp_grouping_accessibility_colour_oos")}`}
    </h3>
  );
}

VariantOutOfStockLabel.propTypes = {
  variantType: PropTypes.oneOf(VARIANT_TYPE).isRequired,
};
