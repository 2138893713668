import classnamesBind from "classnames/bind";
import styles from "./AddToBag.scss";
import Button from "../buttons/button/Button";
import { BUTTON_TYPES } from "../buttons/button/ButtonTypes";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import PropTypes from "prop-types";
import {
  ProgressIndicator,
  SIZE_SMALL,
  COLOUR_SCHEME_INVERTED,
  COLOUR_SCHEME_DEFAULT,
} from "@asosteam/asos-web-component-library-progress-indicator";
import setComponentPerformance from "../../utils/setComponentPerformance";

const classnames = classnamesBind.bind(styles);

function showLoadingAnimation(isSecondaryType) {
  return (
    <ProgressIndicator
      size={SIZE_SMALL}
      colourScheme={
        isSecondaryType ? COLOUR_SCHEME_DEFAULT : COLOUR_SCHEME_INVERTED
      }
    />
  );
}

function showSuccessIndicator({ showSuccess, isSecondaryType }) {
  return (
    showSuccess && (
      <div
        className={
          isSecondaryType ? "product-tick-green" : "product-tick-white"
        }
        data-testid="addToBagSuccess"
      />
    )
  );
}

export default function AddToBag({
  handleAddToBagClick,
  showLoading,
  showSuccess,
  type = BUTTON_TYPES.primaryPurchase,
}) {
  const { getTranslation } = useRuntimeConstants();
  const buttonText = showSuccess
    ? getTranslation("pdp_status_add_to_bag_success")
    : getTranslation("pdp_cta_add_to_bag");

  setComponentPerformance({ metric: "pdp:add_to_bag_interactive" });

  const isSecondaryType =
    type === BUTTON_TYPES.secondaryPurchase ||
    type === BUTTON_TYPES.secondaryPurchaseFullWidth
      ? true
      : false;

  return (
    <Button
      handleClick={handleAddToBagClick}
      type={type}
      dataTestId="add-button"
      ariaLabel={buttonText}
      disabled={showLoading}
    >
      {showSuccessIndicator({ showSuccess, isSecondaryType })}
      <div
        className={classnames(styles.buttonText, {
          iconShown: showSuccess,
          loadingSpinner: showLoading,
        })}
      >
        {showLoading ? showLoadingAnimation(isSecondaryType) : buttonText}
      </div>
    </Button>
  );
}

AddToBag.propTypes = {
  handleAddToBagClick: PropTypes.func.isRequired,
  showLoading: PropTypes.bool.isRequired,
  showSuccess: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
};
