import getWindow from "./window";

const getModalTypes = () => getWindow()?.asos?.siteChrome?.modals?.modalTypes;

const getOpenIFrameModal = () =>
  getWindow()?.asos?.siteChrome?.modals?.openIFrameModal;

const shouldOpenIFrameModal = ({
  openIFrameModal,
  url,
  focusOnCloseElement,
  modalType,
}) => openIFrameModal && url && focusOnCloseElement && modalType;

const openIFrameModal = ({
  url,
  focusOnCloseElement,
  iFrameTitle,
  modalType,
  titleRequired,
}) => {
  const openIFrameModal = getOpenIFrameModal();

  const request = {
    consumer: "pdp",
    url,
    focusOnCloseElement,
    modalType,
  };

  if (
    shouldOpenIFrameModal({
      openIFrameModal,
      url,
      focusOnCloseElement,
      modalType,
    })
  ) {
    if (titleRequired && iFrameTitle) {
      request.iFrameTitle = iFrameTitle;
      return openIFrameModal(request);
    }
    if (!titleRequired) {
      return openIFrameModal(request);
    }
  }
};

export { getModalTypes, openIFrameModal };
