import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./StarRatingGroup.scss";
import { getIntlNumberFormat } from "../../../utils/format";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { StarRating } from "../";
import {
  RATING_TYPES,
  RATINGS_LARGE,
  RATINGS_WITH_REVIEWS_TEXT,
  RATINGS_WITHOUT_REVIEWS_TEXT,
} from "../ratingTypes";

const classnames = classnamesBind.bind(styles);

function getReviewText({ getTranslation, totalReviewCount, language, type }) {
  const formattedNumberOfReviews = getIntlNumberFormat({
    value: totalReviewCount,
    language,
  });
  return type === RATINGS_WITHOUT_REVIEWS_TEXT
    ? formattedNumberOfReviews
    : getTranslation("pdp_ratings_reviews_reviews_count", {
        totalResults: formattedNumberOfReviews,
        smart_count: totalReviewCount,
      });
}

export default function StarRatingGroup({
  averageOverallStarRating,
  averageOverallRating,
  totalReviewCount,
  language,
  type = RATINGS_WITH_REVIEWS_TEXT,
}) {
  const { getTranslation } = useRuntimeConstants();

  return (
    <div
      className={classnames(styles.overallRating, {
        large: type === RATINGS_LARGE,
      })}
      role="region"
      data-testid="reviews-and-product-rating"
      aria-label={getTranslation(
        "pdp_ratings_reviews_accessibility_overall_rating_and_count",
        {
          averageOverallRating,
          totalReviewCount,
        }
      )}
    >
      <StarRating
        rating={averageOverallStarRating}
        large={type === RATINGS_LARGE}
      />
      <div
        aria-hidden="true"
        className={styles.numericRating}
        data-testid="overall-rating"
      >
        {getIntlNumberFormat({
          value: averageOverallRating,
          language,
          options: { minimumFractionDigits: 1 },
        })}
      </div>
      <div aria-hidden="true" data-testid="total-reviews">
        ({getReviewText({ getTranslation, totalReviewCount, language, type })})
      </div>
    </div>
  );
}

StarRatingGroup.propTypes = {
  averageOverallStarRating: PropTypes.number.isRequired,
  averageOverallRating: PropTypes.number.isRequired,
  totalReviewCount: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  type: PropTypes.oneOf(RATING_TYPES),
};
