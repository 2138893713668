import { useEffect, useState } from "react";
import styles from "./VariantSelectionContainer.scss";
import VariantSelector from "../../components/variantSelector/VariantSelector";
import AddToBag from "../../components/addToBag/AddToBag";
import { useAddToBag } from "../../components/addToBag/useAddToBag";
import AddToBagError from "../../components/errors/AddToBagError";
import NoSelectionError from "../../components/errors/NoSelectionError";
import { useVariant } from "../../context/variant/VariantContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useLiveRegion } from "../../context/liveRegion/LiveRegionContext";
import SaveForLaterContainer from "../saveForLaterContainer/SaveForLaterContainer";
import LowInStockMessage from "../../components/lowInStockMessage/LowInStockMessage";
import NotificationButton from "../../components/notificationButton/NotificationButton";
import { BUTTON_TYPES } from "../../components/buttons/button/ButtonTypes";
import PropTypes from "prop-types";
import VariantOutOfStockLabel from "../../components/outOfStockLabels/VariantOutOfStockLabel";
import ShowOnVariantStockStatus from "../../components/conditional/ShowOnVariantStockStatus";
import { getVariantType } from "../../../utils/variantUtils";
import { useProduct } from "../../context/product/ProductContext";
import { LOOKS_RECOMMENDATIONS_HEADER } from "../../constants";
import SeeSimilar from "../../components/looks/seeSimilarButton/SeeSimilarButton";
import isRestockingSoon from "../../utils/restockingSoon";

export default function VariantSelectionContainer({
  shouldShowSave,
  isInStock,
  variantSelectorId,
  fireAddToBagAnalytics,
  fireAddToBagErrorAnalytics,
  fireAddToBagMarketing,
  fireNotifyMeClickAnalytics = () => {},
  scope,
  selectedProductId,
  isLooksProduct,
}) {
  const { updateLiveRegion } = useLiveRegion();
  const [isNoVariantSelectionError, setShowNoVariantSelectionError] =
    useState(false);
  const { getTranslation, isMobile } = useRuntimeConstants();
  const { selectedVariant } = useVariant();
  const { handleAddToBagClick, showLoading, showSuccess, errorMessage } =
    useAddToBag({
      fireAddToBagAnalytics,
      fireAddToBagErrorAnalytics,
      fireAddToBagMarketing,
    });
  const { product } = useProduct();
  const variantType = getVariantType({ product });

  const enhanceAddToBagClick = () => {
    if (!selectedVariant) {
      setShowNoVariantSelectionError(true);
      return;
    }

    handleAddToBagClick();
  };

  const enhanceNotifyMeClick = () => {
    if (!selectedVariant) {
      setShowNoVariantSelectionError(true);
      return;
    }

    fireNotifyMeClickAnalytics(selectedVariant.variantId);
  };

  useEffect(() => {
    if (errorMessage) {
      updateLiveRegion(errorMessage);
    }
  }, [updateLiveRegion, errorMessage]);

  useEffect(() => {
    if (isNoVariantSelectionError) {
      updateLiveRegion(getTranslation("pdp_select_colour_size_message"));
    }

    if (showSuccess) {
      updateLiveRegion(getTranslation("pdp_status_add_to_bag_success"));
    }
  }, [
    updateLiveRegion,
    getTranslation,
    isNoVariantSelectionError,
    showSuccess,
  ]);

  const renderSaveForLater = () =>
    shouldShowSave && (isInStock || isRestockingSoon(product)) ? (
      <SaveForLaterContainer />
    ) : null;

  const getButtonType = () => {
    if (isLooksProduct && !isMobile) {
      return BUTTON_TYPES.secondaryPurchaseFullWidth;
    }

    if (shouldShowSave || isRestockingSoon(product)) {
      return BUTTON_TYPES.primaryPurchase;
    }

    return BUTTON_TYPES.primaryPurchaseFullWidth;
  };

  const renderAddToBag = () => {
    if (selectedVariant?.isInStock || (!selectedVariant && isInStock)) {
      return (
        <>
          <AddToBag
            handleAddToBagClick={enhanceAddToBagClick}
            showLoading={showLoading}
            showSuccess={showSuccess}
            type={getButtonType()}
          />
        </>
      );
    }
  };

  const renderLooksHeader = () => {
    if (scope === LOOKS_RECOMMENDATIONS_HEADER && isInStock) {
      return (
        <ShowOnVariantStockStatus variantStockStatus={false}>
          <VariantOutOfStockLabel variantType={variantType} />
        </ShowOnVariantStockStatus>
      );
    }
  };

  const renderSeeSimilar = () => {
    if (scope === LOOKS_RECOMMENDATIONS_HEADER && !isMobile) {
      return <SeeSimilar selectedProductId={selectedProductId} />;
    }
  };

  return (
    <>
      <VariantSelector
        isNoVariantSelectionError={isNoVariantSelectionError}
        setShowNoVariantSelectionError={setShowNoVariantSelectionError}
        isInStock={isInStock}
        variantSelectorId={variantSelectorId}
      />
      {isNoVariantSelectionError ? <NoSelectionError /> : null}
      {renderLooksHeader()}
      <LowInStockMessage />
      <div className={styles.container} data-testid="primaryActions">
        <NotificationButton
          handleNotifyMeClick={enhanceNotifyMeClick}
          isInStock={isInStock}
          shouldShowSave={shouldShowSave}
          selectedProductId={selectedProductId}
          isLooksProduct={isLooksProduct}
        />
        {renderAddToBag()}
        {renderSeeSimilar()}
        {renderSaveForLater()}
      </div>
      {errorMessage ? <AddToBagError errorMessage={errorMessage} /> : null}
    </>
  );
}

VariantSelectionContainer.propTypes = {
  isInStock: PropTypes.bool,
  shouldShowSave: PropTypes.bool.isRequired,
  variantSelectorId: PropTypes.string,
  fireAddToBagAnalytics: PropTypes.func,
  fireAddToBagErrorAnalytics: PropTypes.func,
  fireAddToBagMarketing: PropTypes.func,
  fireNotifyMeClickAnalytics: PropTypes.func,
  scope: PropTypes.string,
  selectedProductId: PropTypes.number,
  isLooksProduct: PropTypes.bool,
};
