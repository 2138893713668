import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./FitAssistantLink.scss";

const classnames = classnamesBind.bind(styles);

export default function FitAssistantLink({
  fitAssistantLinkText,
  handleFitAssistantLinkClick,
  showFitIcon,
}) {
  return (
    <button
      className={styles.fitAssistantButton}
      onClick={handleFitAssistantLinkClick}
      data-testid="fitAssistantLink"
    >
      {showFitIcon && (
        <span className={styles.iconContainer}>
          <div
            className={classnames(styles.icon, "product-icon-fit-analytics")}
          />
        </span>
      )}
      {fitAssistantLinkText}
    </button>
  );
}

FitAssistantLink.propTypes = {
  fitAssistantLinkText: PropTypes.string.isRequired,
  handleFitAssistantLinkClick: PropTypes.func.isRequired,
  showFitIcon: PropTypes.bool,
};
