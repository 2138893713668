import PropTypes from "prop-types";
import scrollTo from "../../../utils/scrollTo/scrollTo";
import styles from "./StarRatingButton.scss";
import { RATINGS_WITHOUT_REVIEWS_TEXT } from "../ratingTypes";
import { StarRatingGroup } from "../";

const onRatingWrapperClick = (event) => scrollTo("reviewTitle", event);

export default function StarRatingButton({
  averageOverallStarRating,
  averageOverallRating,
  totalReviewCount,
  language,
}) {
  return (
    <button
      onClick={onRatingWrapperClick}
      className={styles.overallRatingButton}
    >
      <StarRatingGroup
        totalReviewCount={totalReviewCount}
        averageOverallStarRating={averageOverallStarRating}
        averageOverallRating={averageOverallRating}
        language={language}
        type={RATINGS_WITHOUT_REVIEWS_TEXT}
      />
    </button>
  );
}

StarRatingButton.propTypes = {
  averageOverallStarRating: PropTypes.number.isRequired,
  averageOverallRating: PropTypes.number.isRequired,
  totalReviewCount: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};
