import SizeGuideButton from "../sizeGuideButton/SizeGuideButton";
import FitAssistantLink from "../fitAssistant/FitAssistantLink";
import { useSizing } from "../../context/sizing/SizingContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function SizeAndFitContainer() {
  const {
    sizeGuideUrl,
    fitAssistantLinkText,
    handleFitAssistantLinkClick,
    showFitIcon,
  } = useSizing();
  const { getTranslation } = useRuntimeConstants();

  const renderSizeGuideButton = () =>
    !!sizeGuideUrl && !fitAssistantLinkText ? (
      <SizeGuideButton
        sizeGuideUrl={sizeGuideUrl}
        text={getTranslation("pdp_size_guide_link")}
      />
    ) : null;

  const renderFitAssistantLink = () =>
    fitAssistantLinkText ? (
      <FitAssistantLink
        fitAssistantLinkText={fitAssistantLinkText}
        handleFitAssistantLinkClick={handleFitAssistantLinkClick}
        showFitIcon={showFitIcon}
      />
    ) : null;

  return (
    <>
      {renderSizeGuideButton()}
      {renderFitAssistantLink()}
    </>
  );
}
