import styles from "./RatingBar.scss";
import PropTypes from "prop-types";
import { getAriaLabel } from "../../utils/ratingsAriaLabels";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const RatingBar = ({ percentage, minLabel, midLabel, maxLabel, label }) => {
  const { getTranslation } = useRuntimeConstants();
  return (
    <div
      aria-label={getAriaLabel(getTranslation, {
        label,
        minLabel,
        midLabel,
        maxLabel,
        percentage,
      })}
      role="img"
    >
      <div
        className={styles.ratingBar}
        data-testid="ratingBar"
        aria-hidden={true}
      >
        <div
          className={styles.ratingProgress}
          style={{ left: `${percentage}%` }}
        />
      </div>
      <div
        className={styles.ratingsLabels}
        data-testid="ratingLabels"
        aria-hidden={true}
      >
        <div>{minLabel}</div>
        {midLabel && <div className={styles.ratingsLabelMid}>{midLabel}</div>}
        <div className={styles.ratingsLabelMax}>{maxLabel}</div>
      </div>
    </div>
  );
};

export default RatingBar;

RatingBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  minLabel: PropTypes.string.isRequired,
  midLabel: PropTypes.string,
  maxLabel: PropTypes.string.isRequired,
};
