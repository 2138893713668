const FIT_ASSISTANT_EVENT_CODES = {
  entryScreen_landed: 1,
  entryScreen_submit: 2,
  entryScreen_tooltip: 3,
  immediateRecommendation_upper: 11,
  immediateRecommendation_lower: 12,
  immediateRecommendation_shoes: 13,
  bcScreen_landed: 14,
  bcScreen_skip: 15,
  bcScreen_submit: 16,
  lbwBcScreen_landed: 17,
  lbwBcScreen_skip: 18,
  lbwBcScreen_submit: 19,
  managePurchasesScreen_landed: 20,
  managePurchasesScreen_submit: 21,
  managePurchasesScreen_skip: 22,
  lbwRiseScreen_landed: 23,
  lbwRiseScreen_submit: 24,
  lbwSizeSelectScreen_landed: 25,
  lbwSizeSelectScreen_submit: 26,
  lbwCategoryScreen_landed: 27,
  lbwCategoryScreen_submit: 28,
  lbwCategoryScreen_skip: 29,
  shapeSelectScreen_1_landed: 30,
  shapeSelectScreen_1_skip: 31,
  shapeSelectScreen_1_submit: 32,
  shapeSelectScreen_1_tooltip: 33,
  shapeSelectScreen_2_landed: 34,
  shapeSelectScreen_2_skip: 35,
  shapeSelectScreen_2_submit: 36,
  shapeSelectScreen_2_tooltip: 37,
  ageScreen_landed: 38,
  ageScreen_skip: 39,
  ageScreen_submit: 40,
  braScreen_landed: 41,
  braScreen_skip: 42,
  braScreen_submit: 43,
  resultsScreen_initial_landed: 44,
  resultsScreen_final_landed: 45,
  sizeRecommendation_returning_lower_results_sp: 46,
  sizeRecommendation_returning_upper_results_sp: 47,
  sizeRecommendation_upper_results_sp: 48,
  sizeRecommendation_lower_results_sp: 49,
  sizeRecommendation_out_of_scale: 50,
  close_lower_results_sp: 51,
  close_upper_results_sp: 52,
  close_brand_compare: 53,
  close_upper_form: 54,
  close_lower_form: 55,
  close_upper_out_of_scale: 56,
  outOfScaleScreen_landed: 57,
  loadingScreen_open: 58,
  loadingScreen_close: 59,
  loadingScreen_timeout: 60,
  entryScreen_close: 61,
  outOfScaleScreen_back: 62,
  outOfScaleScreen_close: 63,
  brandCompareScreen_landed: 64,
  brandCompareScreen_back: 65,
  brandCompareScreen_submit: 66,
  brandCompareScreen_close: 67,
  brandCompareScreen_skip: 68,
  initialResultsScreen_landed: 69,
  initialResultsScreen_close: 70,
  initialResultsScreen_back: 71,
  finalResultsScreen_landed: 72,
  finalResultsScreen_close: 73,
  finalResultsScreen_back: 74,
  tummyShapeScreen_landed: 75,
  tummyShapeScreen_back: 76,
  tummyShapeScreen_submit: 77,
  tummyShapeScreen_close: 78,
  tummyShapeScreen_skip: 79,
  hipShapeScreen_landed: 80,
  hipShapeScreen_back: 81,
  hipShapeScreen_submit: 82,
  hipShapeScreen_close: 83,
  hipShapeScreen_skip: 84,
  ageScreen_back: 86,
  ageScreen_close: 88,
  braScreen_back: 91,
  braScreen_close: 93,
  managePurchasesScreen_back: 96,
  managePurchasesScreen_close: 98,
  lowerCategoryScreen_landed: 100,
  lowerCategoryScreen_back: 101,
  lowerCategoryScreen_submit: 102,
  lowerCategoryScreen_close: 103,
  lowerCategoryScreen_skip: 104,
  fitPreferenceScreen_landed: 105,
  fitPreferenceScreen_submit: 106,
  genderScreen_landed: 107,
  genderScreen_submit: 108,
  immediateRecommendation_out_of_scale: 109,
  lowerBrandScreen_landed: 110,
  lowerBrandScreen_skip: 111,
  lowerBrandScreen_submit: 112,
  lowerModelScreen_landed: 113,
  lowerSizeScreen_landed: 114,
  lowerSizeScreen_submit: 115,
  resultsScreen_returned_landed: 116,
  resultsScreen_submitted_landed: 117,
  shoesBrandScreen_landed: 118,
  shoesBrandScreen_submit: 119,
  shoesSizeScreen_landed: 120,
  shoesSizeScreen_submit: 121,
  shoesWidthScreen_landed: 122,
  shoesWidthScreen_submit: 123,
  "warningScreen_error: loading timeout": 124,
  "warningScreen_error: no fit indicators for result screen": 125,
  "warningScreen_warning: long loading time": 126,
  chestShapeScreen_landed: 127,
  chestShapeScreen_submit: 128,
  close_age: 129,
  close_body_mass: 130,
  close_body_shape: 131,
  close_bra: 132,
  close_fit: 133,
  close_gender: 134,
  close_lower_brand: 135,
  close_lower_size: 136,
  close_lower_type_mobile: 137,
  close_manage_purchases: 138,
  close_out_of_scale: 139,
  close_results: 140,
  close_shoes_brand: 141,
  close_shoes_size: 142,
  close_shoes_type_mobile: 143,
  close_shoes_width_mobile: 144,
  close_warning: 145,
  lowerRiseScreen_landed: 146,
  shoesCategoryScreen_landed: 147,
  shoesCategoryScreen_submit: 148,
  shoesModelScreen_landed: 149,
  shoesModelScreen_skip: 150,
  shoesModelScreen_submit: 151,
  sizeRecommendation_lower_results: 152,
  sizeRecommendation_returning_lower_results: 153,
  sizeRecommendation_returning_shoes_results: 154,
  sizeRecommendation_returning_upper_results: 155,
  sizeRecommendation_shoes_results: 156,
  sizeRecommendation_upper_results: 157,
};

const EXCLUDED_FIT_ASSISTANT_EVENT_CODES_REGEX = [
  /^(resultsScreen_close)$/,
  /^(shapeSelectScreen_close)$/,
  /^(shoes_type_close)$/,
  /^(suggestBrand).*/,
  /^(enterData).*/,
];

function shouldExcludeEvent({ eventName, value }) {
  return EXCLUDED_FIT_ASSISTANT_EVENT_CODES_REGEX.some((regex) =>
    `${eventName}_${value}`.match(regex)
  );
}

export function getFitAssistantEventCode({ eventName, value }) {
  if (!eventName || !value || shouldExcludeEvent({ eventName, value })) {
    return;
  }

  return (
    FIT_ASSISTANT_EVENT_CODES[`${eventName}_${value}`] ||
    `${eventName}:${value}`
  );
}

export function getFitAssistantEventCodeString({ metrics }) {
  return metrics
    .map(({ event, value }) =>
      getFitAssistantEventCode({ eventName: event, value })
    )
    .filter((eventCode) => eventCode)
    .join(",");
}
