import { useMemo } from "react";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { getPaymentPromoMessage } from "./utils";

const usePaymentPromoMessage = (price, paymentPromoConfig) => {
  const { webContext } = useRuntimeConstants();

  return useMemo(
    () =>
      getPaymentPromoMessage({
        paymentPromoConfig,
        price,
        webContext,
      }),
    [paymentPromoConfig, price, webContext]
  );
};

export default usePaymentPromoMessage;
