import { useEffect, useState } from "react";
import { getFeature } from "../utils/features";
import { useFeaturesReady } from "../context/featuresReady/FeaturesReadyContext";

export default function useDiscountPercentage() {
  const [showDiscountPercentage, setShowDiscountPercentage] = useState(false);
  const { areFeaturesReady } = useFeaturesReady();

  useEffect(() => {
    if (areFeaturesReady) {
      setShowDiscountPercentage(
        getFeature("all-show-discount-label").isEnabled()
      );
    }
  }, [areFeaturesReady]);

  return { showDiscountPercentage };
}
