import { variantDataTemplate } from "./variantDataTemplate";

export function mapProductsFromVariantsAcrossMultipleProducts({
  mixAndMatchProducts,
  getUnitsAndQuantity,
}) {
  return mixAndMatchProducts
    .map((product) =>
      mapProductsFromVariants({
        product,
        variants: product.variants,
        getUnitsAndQuantity,
      })
    )
    .flat();
}

export const mapProductsFromVariants = ({
  product,
  variants,
  getUnitsAndQuantity,
}) =>
  variants.map((variant) =>
    variantDataTemplate({
      product,
      variant,
      getUnitsAndQuantity,
    })
  );

export const getProductName = ({ products }, variant) =>
  products.find((product) => product.id === variant.productId)?.name;

export const hasSubProducts = (product) => !!product?.products;

export const getTotalValueFromAddedVariants = (productsAdded) =>
  productsAdded.reduce((sum, product) => {
    const price = product.priceLocalCurrency
      ? product.priceLocalCurrency
      : product.priceObj?.current.value;
    return sum + price;
  }, 0);

export const getVariantStockStatus = (variants) =>
  variants.map((variant) => ({
    variantId: variant.variantId,
    variantSku: variant.sku,
    stockStatus: variant.isInStock ? "in stock" : "out of stock",
  }));

export const getTotalInStock = (variants) =>
  variants.filter((variant) => variant.isInStock === true).length;
