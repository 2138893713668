import PropTypes from "prop-types";
import styles from "./BrandedSpinner.scss";
import classnamesBind from "classnames";
import StandardSpinner from "../standard/StandardSpinner";
import { MEDIUM_SPINNER } from "../../../constants";

const classnames = classnamesBind.bind(styles);

export default function BrandedSpinner({ colour }) {
  const logoClass =
    colour === "white"
      ? "product-asos-spinner-logo-white"
      : "product-asos-spinner-logo";
  const colourClass = colour ? colour : "black";

  return (
    <StandardSpinner
      additionalClasses={classnames(styles.spinner, styles[colourClass])}
      size={MEDIUM_SPINNER}
      position={"absolute"}
    >
      <div className={classnames(styles.spinnerLogo, logoClass)} />
    </StandardSpinner>
  );
}

BrandedSpinner.propTypes = {
  colour: PropTypes.oneOf(["black", "white"]),
};
