import { useEffect, useState, useRef } from "react";
import getWindow from "../utils/window";

let savedRootElements = [];

const tabPressed = (event) => event.key === "Tab" || event.keyCode === 9;

const getKeyboardFocusableElements = (rootElement) => {
  const keyboardFocusableElements = Array.from(
    rootElement.querySelectorAll(
      "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), object, embed, *[tabindex]:not([tabindex^='-']), *[contenteditable]"
    )
  );
  return {
    firstElement: keyboardFocusableElements[0],
    lastElement:
      keyboardFocusableElements[keyboardFocusableElements.length - 1],
  };
};

const shouldFocusLastElement = (event, firstElement) =>
  event.shiftKey && getWindow().document.activeElement === firstElement;

const shouldFocusFirstElement = (event, lastElement) =>
  !event.shiftKey && getWindow().document.activeElement === lastElement;

const simulateShiftTab = (catchAllStopper) => {
  const KeyboardEvt = getWindow().KeyboardEvent;
  catchAllStopper.dispatchEvent(new KeyboardEvt("keydown", { key: "Shift" }));
  catchAllStopper.dispatchEvent(new KeyboardEvt("keydown", { key: "Tab" }));
  catchAllStopper.dispatchEvent(new KeyboardEvt("keyup", { key: "Shift" }));
};

const handleTabPress = ({ event, firstElement, lastElement }) => {
  if (shouldFocusLastElement(event, firstElement)) {
    event.preventDefault();
    lastElement.focus();
  }

  if (shouldFocusFirstElement(event, lastElement)) {
    event.preventDefault();
    firstElement.focus();
  }
};

function shouldIgnoreEvent({ firstElement, savedRootElements, trapId }) {
  return (
    !firstElement ||
    !savedRootElements.length ||
    savedRootElements[savedRootElements.length - 1].trapId !== trapId
  );
}

const handleKeyDownNoCatchAll = (rootElement, trapId) => (event) => {
  const { firstElement, lastElement } =
    getKeyboardFocusableElements(rootElement);

  if (shouldIgnoreEvent({ firstElement, savedRootElements, trapId })) {
    return;
  }

  if (tabPressed(event)) {
    handleTabPress({ event, firstElement, lastElement });
  }
};

const handleKeyDownWithCatchAll =
  (rootElement, trapId, catchAllStopper) => (event) => {
    const { firstElement } = getKeyboardFocusableElements(rootElement);

    if (shouldIgnoreEvent({ firstElement, savedRootElements, trapId })) {
      return;
    }

    if (tabPressed(event)) {
      if (shouldFocusLastElement(event, firstElement)) {
        catchAllStopper.focus();
      }
    }
  };

const handleKeyDown = (useCatchAll) =>
  useCatchAll ? handleKeyDownWithCatchAll : handleKeyDownNoCatchAll;

const redirectFocus = (rootElement, catchAllStopper) => (event) => {
  const { firstElement } = getKeyboardFocusableElements(rootElement);
  event.preventDefault();

  if (event.relatedTarget === firstElement) {
    simulateShiftTab(catchAllStopper);
  } else {
    firstElement.focus();
  }
};

const createCatchAll = (rootElement) => {
  const catchAllStopper = getWindow().document.createElement("div");
  catchAllStopper.setAttribute("tabindex", "0");
  catchAllStopper.setAttribute(
    "style",
    "position:absolute;width:0;height:0;left:-2px;"
  );
  catchAllStopper.addEventListener(
    "focus",
    redirectFocus(rootElement, catchAllStopper)
  );
  rootElement.appendChild(catchAllStopper);
  return catchAllStopper;
};

function alreadyTrapped(trapId) {
  return savedRootElements.some(
    ({ trapId: savedTrapId }) => savedTrapId === trapId
  );
}

function removeTrappedNode(trapId) {
  savedRootElements = savedRootElements.filter(
    ({ trapId: savedTrapId }) => savedTrapId !== trapId
  );
}

export default function useTrapFocus({ resetTrap, useCatchAll } = {}) {
  const catchAllStopper = useRef(null);
  const [trapId] = useState(Math.round(Math.random() * 100000));
  const [rootElement, setRootElement] = useState(null);

  useEffect(() => {
    let handler;
    if (rootElement) {
      if (useCatchAll) {
        catchAllStopper.current = createCatchAll(rootElement);
      }
      handler = handleKeyDown(useCatchAll)(
        rootElement,
        trapId,
        catchAllStopper.current
      );
      rootElement.addEventListener("keydown", handler);
    }

    return () => {
      if (handler) {
        rootElement?.removeEventListener("keydown", handler);
        catchAllStopper.current?.remove();
      }
    };
  }, [resetTrap, useCatchAll, rootElement, catchAllStopper, trapId]);

  useEffect(() => {
    return () => {
      removeTrappedNode(trapId);
    };
  }, [trapId]);

  return function setElementToTrap(node) {
    setRootElement(node);
    if (node && !alreadyTrapped(trapId)) {
      savedRootElements.push({ node, trapId });
    }
  };
}
