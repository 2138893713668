import { getCommons, getGlobalEvars } from "./common";
import getConstants from "../runtimeConstants";
import { waitForPageLoadAnalytics } from "./pageLoad";
import {
  getAverageOverallRating,
  getFirstSubRatingValue,
  getPercentageRecommended,
  getTotalReviewCount,
} from "./ratings";

const sendAnalytics = async ({
  context,
  trackVars = [],
  trackEvents = [],
  events = [],
}) => {
  await waitForPageLoadAnalytics;

  const { product } = getConstants();
  const commons = getCommons();
  const s = window.s;

  s.events = [...events, "event18"].join(",");

  s.products = ";" + product.id;

  const overrides = {
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      "prop15",
      ...trackVars,
    ]),
    linkTrackEvents: commons.setLinkTrackEvents(["event18", ...trackEvents]),
  };

  s.tl(true, "o", context, overrides);
};

export function fireClearAllAnalytics() {
  const s = window.s;

  s.prop15 = "recently viewed|clear all";

  sendAnalytics({ context: "recently viewed clear all" });
}

export function fireRatingsAndReviewsOpenAnalytics(ratings) {
  const s = window.s;
  const context = "open ratings & reviews";

  s.prop15 = context;

  const { event257 } = getAverageOverallRating(ratings);
  const { event258 } = getTotalReviewCount(ratings);
  const { eVar187, event259 } = getPercentageRecommended(ratings);
  const { eVar188, event260 } = getFirstSubRatingValue(ratings);

  s.eVar187 = eVar187;
  s.eVar188 = eVar188;

  const events = [event257, event258];

  if (event259) {
    events.push(event259);
  }
  if (event260) {
    events.push(event260);
  }

  const trackVars = ["eVar185", "eVar186", "eVar187", "eVar188"];
  const trackEvents = ["event257", "event258", "event259", "event260"];

  sendAnalytics({ context, trackVars, trackEvents, events });
}

export function fireAfsAnalytics({ variantId, sellerId, sourceId }) {
  // these analytics are fire AF
  const s = window.s;
  const context = "shipping from icon click";

  s.prop15 = context;
  s.eVar210 = `${variantId}:${
    sellerId === sourceId ? `` : `afs - `
  }${sourceId.toLowerCase()}`;

  const trackVars = ["eVar210", "eVar212"];
  const trackEvents = ["event286"];

  sendAnalytics({ context, trackVars, trackEvents, events: ["event286"] });
}

export function fireFlexFulfilmentAnalytics() {
  const s = window.s;
  const context = "shipping from icon click";

  s.prop15 = context;

  const trackVars = ["eVar210", "eVar212"];
  const trackEvents = ["event286"];

  sendAnalytics({ context, trackVars, trackEvents, events: ["event286"] });
}

export function fireShippingRestrictionsAnalytics() {
  const s = window.s;

  s.trackingShippingRestrictionsClick = 1;
  s.prop15 = "shipping restrictions";

  sendAnalytics({
    context: "shipping restrictions",
  });
}

export function fireMightLikeVisibleAnalytics() {
  const s = window.s;

  s.prop15 = "ymal impression";

  sendAnalytics({ context: "ymal impression" });
}

export function fireIngredientsButtonClickAnalytics() {
  const s = window.s;

  s.prop15 = "ingredients list";

  sendAnalytics({
    context: "ingredients list",
  });
}

export function fireYMALTabAnalytics(tabText) {
  const s = window.s;
  const context = "YMAL Tab click";

  s.prop15 = context;
  s.eVar227 = tabText;

  const trackVars = ["eVar227"];
  const trackEvents = ["event297"];

  sendAnalytics({ context, trackVars, trackEvents, events: ["event297"] });
}

export function fireProductDetailsAccordionItemOpened(accordionItemTitle) {
  const s = window.s;
  const context = "product information accordion open";
  s.prop15 = `Accordion - ${accordionItemTitle}`;

  sendAnalytics({ context });
}

export function fireSocialShareIntent(platformId) {
  const s = window.s;
  let context = "social share intent";

  if (platformId) {
    context = `${context}|${platformId}`;
  }

  s.prop15 = context;

  sendAnalytics({ context });
}

export function firePricingModalOpen(modalName) {
  const context = `open ${modalName} price info`;

  sendAnalytics({ context, trackEvents: ["event333"], events: ["event333"] });
}

export function fireSustainabilityInfoLinkClick(isSuccess) {
  const s = window.s;
  const context = "Product Sheet Click";

  s.prop15 = `product sheet link click - ${isSuccess ? "Success" : "Error"}`;
  sendAnalytics({ context });
}

export function fireSizeGuideButtonClickAnalytics() {
  const s = window.s;
  const context = "size guide click";

  s.prop15 = "launch size guide";
  sendAnalytics({
    context,
    trackVars: ["eVar183"],
    trackEvents: ["event103"],
    events: ["event103"],
  });
}
