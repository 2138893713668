import { useState, useRef, useEffect } from "react";
import { useVariant } from "../../context/variant/VariantContext";
import { addItemToBag } from "./bag";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { ERROR_TYPE, logError } from "../../utils/newRelicLogger";
import { useProduct } from "../../context/product/ProductContext";
import { useSizing } from "../../context/sizing/SizingContext";

const SUCCESS_DISPLAY_DURATION = 5000;

const useSuccessState = ({ selectedVariant }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const timeout = useRef();

  function displaySuccessState() {
    setShowSuccess(true);
    timeout.current = setTimeout(
      () => setShowSuccess(false),
      SUCCESS_DISPLAY_DURATION
    );
  }

  function resetSuccessState() {
    clearTimeout(timeout.current);
    setShowSuccess(false);
  }

  useEffect(() => resetSuccessState, [timeout, selectedVariant]);

  return { showSuccess, displaySuccessState, resetSuccessState };
};

const formatErrorMessage = (error) => {
  let formattedString = error.userMessage;
  if (Array.isArray(error.messageContext)) {
    error.messageContext.forEach((context, index) => {
      formattedString = formattedString.replace(`{${index}}`, context);
    });
  }
  return { ...error, userMessage: formattedString };
};

const getErrorMessage = ({ error, getTranslation }) => {
  if (Array.isArray(error.message) && error.message[0].userMessage) {
    return formatErrorMessage(error.message[0]);
  }
  return {
    userMessage: getTranslation("pdp_status_add_to_bag_failure"),
    errorMessage: getTranslation("pdp_status_add_to_bag_failure"),
    errorCode: null,
  };
};

export const useAddToBag = ({
  fireAddToBagAnalytics = () => {},
  fireAddToBagErrorAnalytics = () => {},
  fireAddToBagMarketing = () => {},
} = {}) => {
  const { getTranslation, ratings } = useRuntimeConstants();
  const { selectedVariant } = useVariant();
  const { product } = useProduct();
  const { sizeGuideOpened } = useSizing();
  const { showSuccess, displaySuccessState, resetSuccessState } =
    useSuccessState({ selectedVariant });
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setErrorMessage(null);
    setShowLoading(false);
  }, [selectedVariant]);

  async function handleAddToBagClick() {
    setErrorMessage(null);
    if (selectedVariant) {
      resetSuccessState();
      setShowLoading(true);

      try {
        await addItemToBag({
          selectedVariant,
        });

        setShowLoading(false);
        displaySuccessState();
        fireAddToBagAnalytics({
          selectedVariant,
          product,
          ratings,
          sizeGuideOpened,
        });
        fireAddToBagMarketing({
          products: product,
          variantsAddedList: [selectedVariant],
        });
      } catch (error) {
        const { userMessage, errorCode, errorMessage } = getErrorMessage({
          error,
          getTranslation,
        });
        setShowLoading(false);
        setErrorMessage(userMessage);
        logError(ERROR_TYPE.UserActionFailed, {
          action: "AddToBag",
          ...error,
          ...error.message?.[0],
        });
        fireAddToBagErrorAnalytics &&
          fireAddToBagErrorAnalytics({
            selectedVariant,
            product,
            ratings,
            sizeGuideOpened,
            errorCode,
            errorMessage,
          });
      }
    }
  }

  return {
    handleAddToBagClick,
    showLoading,
    showSuccess,
    errorMessage,
  };
};
