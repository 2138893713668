import styles from "./ProductColour.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { PropTypes } from "prop-types";
import { PRODUCT_COLOUR_TYPES, TEXT_SIZES } from "../../constants";
import classnamesBind from "classnames/bind";

const classnames = classnamesBind.bind(styles);

const ProductColour = ({ colourLabel, type, size = TEXT_SIZES.leavesden2 }) => {
  const { getTranslation } = useRuntimeConstants();

  return (
    <div
      data-testid="productColour"
      className={classnames(styles.productColour, type)}
    >
      <h2 className={styles.colourTitle}>
        {getTranslation("pdp_misc_colour")}:
      </h2>
      <p className={classnames(styles.colourValue, styles[size])}>
        {colourLabel}
      </p>
    </div>
  );
};

ProductColour.propTypes = {
  type: PropTypes.oneOf(PRODUCT_COLOUR_TYPES),
  size: PropTypes.oneOf(TEXT_SIZES),
  colourLabel: PropTypes.string.isRequired,
};

export default ProductColour;
