const ariaTranslations = {
  2: [
    {
      minValue: 0,
      maxValue: 33,
      labelKey: "pdp_ratings_reviews_accessibility_slider_0_25_description",
    },
    {
      minValue: 34,
      maxValue: 65,
      labelKey: "pdp_ratings_reviews_accessibility_slider_25_35_description",
    },
    {
      minValue: 66,
      maxValue: 100,
      labelKey: "pdp_ratings_reviews_accessibility_slider_75_100_description",
    },
  ],
  3: [
    {
      minValue: 0,
      maxValue: 24,
      labelKey: "pdp_ratings_reviews_accessibility_slider_0_25_description",
    },
    {
      minValue: 25,
      maxValue: 34,
      labelKey: "pdp_ratings_reviews_accessibility_slider_25_35_description",
    },
    {
      minValue: 35,
      maxValue: 65,
      labelKey: "pdp_ratings_reviews_accessibility_slider_35_65_description",
    },
    {
      minValue: 66,
      maxValue: 75,
      labelKey: "pdp_ratings_reviews_accessibility_slider_65_75_description",
    },
    {
      minValue: 76,
      maxValue: 100,
      labelKey: "pdp_ratings_reviews_accessibility_slider_75_100_description",
    },
  ],
};

function getNumberOfLabels({ minLabel, midLabel, maxLabel }) {
  return [minLabel, midLabel, maxLabel].filter((label) => label).length;
}

function getAriaLabelForTwoLabels(
  getTranslation,
  { label, minLabel, maxLabel, percentage }
) {
  const midLabel = maxLabel;
  const { labelKey } = ariaTranslations[2].find(({ minValue, maxValue }) => {
    return percentage >= minValue && percentage <= maxValue;
  });
  return getTranslation(labelKey, {
    secondaryRatingAverage: label,
    minLabel,
    midLabel,
    maxLabel,
  });
}

function getAriaLabelForThreeLabels(
  getTranslation,
  { label, minLabel, midLabel, maxLabel, percentage }
) {
  const { labelKey } = ariaTranslations[3].find(({ minValue, maxValue }) => {
    return percentage >= minValue && percentage <= maxValue;
  });
  return getTranslation(labelKey, {
    secondaryRatingAverage: label,
    minLabel,
    midLabel,
    maxLabel,
  });
}

export function getAriaLabel(
  getTranslation,
  { label, minLabel, midLabel, maxLabel, percentage }
) {
  const numberOfLabels = getNumberOfLabels({ minLabel, midLabel, maxLabel });

  if (numberOfLabels === 3) {
    return getAriaLabelForThreeLabels(getTranslation, {
      label,
      minLabel,
      midLabel,
      maxLabel,
      percentage,
    });
  } else if (numberOfLabels === 2) {
    return getAriaLabelForTwoLabels(getTranslation, {
      label,
      minLabel,
      maxLabel,
      percentage,
    });
  }
}
