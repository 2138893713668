import {
  mapProductsFromVariants,
  mapProductsFromVariantsAcrossMultipleProducts,
  getTotalValueFromAddedVariants,
  getVariantStockStatus,
  getTotalInStock,
} from "./marketingTrackingUtils";
import { variantDataTemplate } from "./variantDataTemplate";
import { getTotalUniqueSizes } from "../getTotalUniqueSizes";

export function addToBagMultipleProductTemplate({
  product,
  variantsAddedList,
}) {
  const productsAdded = mapProductsFromVariants({
    product,
    variants: variantsAddedList,
    getUnitsAndQuantity: true,
  });

  return {
    event: "add_to_cart",
    context: "addToBag",
    totalValue: getTotalValueFromAddedVariants(productsAdded),
    productsAdded: productsAdded.length,
    addToBagType: "main",
    ecommerce: {
      currencyCode: "GBP",
      localCurrencyCode: variantsAddedList[0].priceObj.currency,
      add: {
        products: productsAdded,
      },
    },
  };
}

export function addToBagSingleProductTemplate({ product, variantAdded }) {
  const productAdded = variantDataTemplate({
    product,
    variant: variantAdded,
    getUnitsAndQuantity: true,
  });

  return {
    event: "add_to_cart",
    context: "addToBag",
    totalValue: productAdded.priceLocalCurrency,
    productsAdded: 1,
    addToBagType: "main",
    ecommerce: {
      currencyCode: "GBP",
      localCurrencyCode: variantAdded.price.currency,
      add: {
        products: [productAdded],
      },
    },
  };
}

export function saveForLaterTemplate({ product, variantsAdded }) {
  const productAdded = variantsAdded.map((variant) =>
    variantDataTemplate({
      product,
      variant,
      getUnitsAndQuantity: true,
    })
  );
  return {
    event: "saveForLater",
    context: "saveForLater",
    ecommerce: {
      currencyCode: "GBP",
      localCurrencyCode: variantsAdded[0].price.currency,
      save: {
        products: productAdded,
      },
    },
  };
}

export function pageLoadMixAndMatchTemplate(
  parentProduct,
  { analyticsBreadcrumbs, hostAndPath, urlParams }
) {
  const mixAndMatchProducts = parentProduct.products;

  return {
    context: "pageLoad",
    availableProducts: mixAndMatchProducts.map(({ id }) => id),
    event: "view_item",
    productUrl: hostAndPath,
    cid: urlParams.cid || null,
    breadCrumb: analyticsBreadcrumbs,
    ecommerce: {
      currencyCode: "GBP",
      localCurrencyCode: mixAndMatchProducts[0].price.currency,
      detail: {
        actionField: {
          list: "Mix and Match",
        },
        products: mapProductsFromVariantsAcrossMultipleProducts({
          mixAndMatchProducts,
          getUnitsAndQuantity: false,
        }),
      },
    },
  };
}

export function pageLoadSingleProductTemplate(
  product,
  { analyticsBreadcrumbs, hostAndPath, urlParams }
) {
  return {
    context: "pageLoad",
    event: "view_item",
    productUrl: hostAndPath,
    cid: urlParams.cid || null,
    breadCrumb: analyticsBreadcrumbs,
    productId: product.id,
    productCode: product.productCode,
    originalPrice: product.price?.xrp,
    currentPrice: product.price?.current,
    availableSku: getTotalInStock(product.variants),
    availableSizes: getTotalUniqueSizes({ variants: product.variants }),
    VariantStockStatus: getVariantStockStatus(product.variants),
    ecommerce: {
      currencyCode: "GBP",
      localCurrencyCode: product.variants[0].price.currency,
      detail: {
        actionField: {
          list: "Single PDP",
        },
        products: mapProductsFromVariants({
          product,
          variants: product.variants,
        }),
      },
    },
  };
}
