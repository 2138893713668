import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";

import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./OutOfStockLabel.scss";
import { LABEL_TYPES, PRIMARY_LABEL, SECONDARY_LABEL } from "./labelTypes";
import { useProduct } from "../../context/product/ProductContext";
import isRestockingSoon from "../../utils/restockingSoon";

const classnames = classnamesBind.bind(styles);

export default function OutOfStockLabel({ labelStyle = PRIMARY_LABEL }) {
  const { getTranslation } = useRuntimeConstants();
  const { product } = useProduct();

  if (isRestockingSoon(product)) {
    return null;
  }

  return (
    <h3
      id={styles.oosLabelText}
      data-testid="outOfStockLabel"
      className={classnames({
        looksProductTextLabel: labelStyle === SECONDARY_LABEL,
        primaryTextLabel: labelStyle === PRIMARY_LABEL,
      })}
    >
      {getTranslation("pdp_status_out_of_stock")}
    </h3>
  );
}

OutOfStockLabel.propTypes = {
  labelStyle: PropTypes.oneOf(LABEL_TYPES),
};
