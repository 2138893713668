import { fireMarketingEvent } from "./fireMarketingEvent";
import getRuntimeConstants from "../../runtimeConstants";
import {
  saveForLaterTemplate,
  addToBagSingleProductTemplate,
  addToBagMultipleProductTemplate,
  pageLoadMixAndMatchTemplate,
  pageLoadSingleProductTemplate,
} from "./marketingTrackingTemplates";

export const fireSaveForLaterMarketing = (product, variantsAdded) =>
  fireMarketingEvent(
    "saveForLater",
    saveForLaterTemplate({
      product,
      variantsAdded,
    })
  );

export const fireAddToBagMarketing = ({ products, variantsAddedList }) => {
  const { isMixAndMatch } = getRuntimeConstants();

  if (variantsAddedList.length < 1 || !products) {
    return;
  }

  if (isMixAndMatch && variantsAddedList.length > 1) {
    fireMarketingEvent(
      "addToBag",
      addToBagMultipleProductTemplate({
        product: products,
        variantsAddedList,
      })
    );
    return;
  }

  fireMarketingEvent(
    "addToBag",
    addToBagSingleProductTemplate({
      product: products,
      variantAdded: variantsAddedList[0],
    })
  );
};

export const firePageLoadMarketing = async ({ products }) => {
  const {
    isMixAndMatch,
    analyticsBreadcrumbs,
    hostAndPath,
    urlParams,
    product,
  } = getRuntimeConstants();

  if (product.isDiscontinued || !products) {
    return;
  }

  const config = { analyticsBreadcrumbs, hostAndPath, urlParams };

  if (isMixAndMatch) {
    fireMarketingEvent(
      "pageLoad",
      pageLoadMixAndMatchTemplate(products, config)
    );
    return;
  }

  if (!products.variants.length) {
    return;
  }

  fireMarketingEvent(
    "pageLoad",
    pageLoadSingleProductTemplate(products, config)
  );
};
