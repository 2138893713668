import makeRequest from "./makeRequest";
import getRuntimeConstants from "../runtimeConstants";

export default async function getDeliveryOptions() {
  const {
    webContext: { keyStoreDataversion, browseLanguage, storeId },
    deliveryOptionsApiEndpoint,
    deliveryOptionsApiTimeout,
  } = getRuntimeConstants();

  const params = {
    keyStoreDataversion,
    lang: browseLanguage,
    store: storeId,
  };

  return makeRequest({
    url: deliveryOptionsApiEndpoint,
    params,
    timeout: deliveryOptionsApiTimeout,
    requestType: "DELIVERY_OPTIONS_API",
    apiName: "Commerce",
    caller: "DeliveryOptions",
    handleErrorReturnValue: () => ({}),
  });
}
