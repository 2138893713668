import { getProductName, hasSubProducts } from "./marketingTrackingUtils";

export function variantDataTemplate({ product, variant, getUnitsAndQuantity }) {
  const template = {
    productId: hasSubProducts(product) ? variant.productId : product.id,
    brand: product.brandName,
    id: variant.sku,
    variantId: variant.variantId,
    variant: variant.colour,
    productCode: product.productCode,
    name: mapProductName(product, variant),
    price: mapProductPrice(variant),
    priceLocalCurrency: mapProductPriceLocalCurrency(variant),
  };

  if (product.productType) {
    template.category = product.productType.name;
  }

  if (product.sellingFast) {
    template.sellingFast = product.sellingFast;
  } else {
    template.sellingFast = false;
  }

  if (getUnitsAndQuantity) {
    template.quantity = 1;
    template.units = 1;
  }

  return template;
}

function mapProductName(product, variant) {
  return hasSubProducts(product)
    ? getProductName(product, variant)
    : product.name;
}

function mapProductPrice(variant) {
  if (variant.priceObj) {
    return variant.priceObj.xrp.value;
  } else {
    return variant.price?.xrp.value;
  }
}

function mapProductPriceLocalCurrency(variant) {
  if (variant.priceObj) {
    return variant.priceObj.current.value;
  } else {
    return variant.price?.current.value;
  }
}
