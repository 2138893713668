import PropTypes from "prop-types";
import { useProduct } from "../../context/product/ProductContext";
import { productIsInStock } from "../../utils/product";

export default function ShowOnProductStockStatus({
  children,
  productStockStatus,
}) {
  const { product } = useProduct();

  return productIsInStock(product) === productStockStatus ? (
    <>{children}</>
  ) : null;
}

ShowOnProductStockStatus.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  productStockStatus: PropTypes.bool.isRequired,
};
