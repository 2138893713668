import { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./SizeGuideButton.scss";
import classnamesBind from "classnames/bind";
import { fireSizeGuideButtonClickAnalytics } from "../../analytics/pageInteraction";
import getWindow from "../../utils/window";
import { useSizing } from "../../context/sizing/SizingContext";

const classnames = classnamesBind.bind(styles);

export const SIZE_GUIDE_BUTTON_SIZE = {
  SMALL: "small",
  LARGE: "large",
};

export default function SizeGuideButton({
  sizeGuideUrl,
  text,
  size = SIZE_GUIDE_BUTTON_SIZE.SMALL,
}) {
  const { setSizeGuideOpened } = useSizing();
  const buttonRef = useRef(null);

  return (
    !!sizeGuideUrl && (
      <button
        className={classnames(styles.sizeGuideButton, { [size]: size })}
        data-testid="size-guide-button"
        ref={buttonRef}
        onClick={() => {
          setSizeGuideOpened(true);
          fireSizeGuideButtonClickAnalytics();

          getWindow().asos.eventBus.emit("sizeGuideOpen", {
            url: sizeGuideUrl,
            focusOnClose: buttonRef.current,
          });
        }}
      >
        {text}
      </button>
    )
  );
}

SizeGuideButton.propTypes = {
  sizeGuideUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZE_GUIDE_BUTTON_SIZE)),
};
