import styles from "./LowInStockMessage.scss";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useVariant } from "../../context/variant/VariantContext";
import { useProduct } from "../../context/product/ProductContext";
import { productHasMultipleVariants } from "../../utils/product";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import { useSelectedLooksProduct } from "../../context/looks/SelectedLooksProductContext";

const classnames = classnamesBind.bind(styles);

function singleVariantLowInStock({ product }) {
  return (
    !productHasMultipleVariants(product) &&
    product?.variants[0].isLowInStock === true
  );
}

function shouldShowLowInStock({ product, selectedVariant }) {
  return singleVariantLowInStock({ product }) || selectedVariant?.isLowInStock;
}

export default function LowInStockMessage() {
  const { getTranslation } = useRuntimeConstants();
  const { selectedVariant } = useVariant();
  const { product } = useProduct();
  const { isInStock } = useIsInStockContext();
  const { selectedLooksProduct } = useSelectedLooksProduct();

  if (!(isInStock && shouldShowLowInStock({ product, selectedVariant }))) {
    return null;
  }

  const isLooksProduct = product.id === selectedLooksProduct?.id;

  return (
    <div className={styles.isLowInStock}>
      {!isLooksProduct && (
        <div className={classnames("product-clock", styles.icon)} />
      )}
      <span
        className={classnames(styles.lowInStockMessage, {
          isLooks: isLooksProduct,
        })}
        data-testid="lowInStock"
      >
        {getTranslation("pdp_status_low_in_stock")}
      </span>
    </div>
  );
}
