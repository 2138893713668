import { getPrice, isDiscounted } from "../../../utils/discountPriceData";
import { GRID_PRICE } from "../priceTypes";
import PropTypes from "prop-types";
import CurrentPrice from "../currentPrice/CurrentPrice";
import PreviousPrice from "../previousPrice/PreviousPrice";
import styles from "./GridPrice.scss";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import PercentageDiscount from "../percentageDiscount/PercentageDiscount";

export default function GridPrice({ priceData }) {
  const { getTranslation } = useRuntimeConstants();
  const isPriceDiscounted = isDiscounted(priceData);
  const { currentPriceText, previousPriceText, percentageDiscountLabel } =
    getPrice({ translations: getTranslation, priceData });

  return (
    <div className={styles.gridPrice}>
      <span className={styles.priceAndDiscount}>
        <CurrentPrice
          currentPriceText={currentPriceText}
          showVatMessage={false}
          priceStyle={GRID_PRICE}
          isSalePrice={isPriceDiscounted}
        />
        {isPriceDiscounted && (
          <PercentageDiscount
            percentageDiscountLabel={percentageDiscountLabel}
          />
        )}
      </span>
      {isPriceDiscounted && (
        <PreviousPrice
          previousPriceText={previousPriceText}
          priceStyle={GRID_PRICE}
        />
      )}
    </div>
  );
}

GridPrice.propTypes = {
  priceData: PropTypes.shape({
    current: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number,
    }).isRequired,
    previous: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number,
    }),
    rrp: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number,
    }),
    isMarkedDown: PropTypes.bool,
    isOutletPrice: PropTypes.bool,
  }).isRequired,
};
