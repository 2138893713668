import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./StarRating.scss";

const classnames = classnamesBind.bind(styles);

export default function StarRating({ rating, large }) {
  return (
    <div
      className={classnames({
        [styles.container]: !large,
        [styles.containerLarge]: large,
      })}
    >
      <div className={styles.starRating}>
        {[1, 2, 3, 4, 5].map((index) => {
          let className = "product-ratings-empty";
          if (index - rating <= 0) {
            className = "product-ratings-full";
          }
          if (index - rating === 0.5) {
            className = "product-ratings-half";
          }
          return (
            <span
              key={`star-${index}`}
              className={classnames(className, styles.star)}
            />
          );
        })}
      </div>
    </div>
  );
}

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  large: PropTypes.bool,
};
