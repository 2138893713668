import styles from "./Errors.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function NoSelectionError() {
  const { getTranslation } = useRuntimeConstants();

  return (
    <div
      className={styles.errorMessage}
      data-testid="no-selection-error-message"
    >
      {getTranslation("pdp_select_colour_size_message")}
    </div>
  );
}
